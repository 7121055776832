var bvUsageConst = {
    Entity: {
        Type : {
            Survey: "Survey",
            Approval: "Approval",
            Meeting: "Meeting",
            NewItem: "NewItem",
            Document: "Document",
            Agenda: "Agenda"
        },
        Action: {            
            Survey: {
                MenuAction: "MenuAction",
                SelectedTab: "SelectedTab"
            },
            Approval: {
                ApprovalSubmitted: "ApprovalSubmitted",
                ApprovalRequested: "ApprovalRequested",
                MenuAction: "MenuAction",
                SelectedTab: "SelectedTab"
            },
            Meeting: {
                SaveMeeting: "SaveMeeting",
                PublishMeeting: "PublishMeeting",
                NoOfParticipants: "NoOfParticipants",
                NoOfAttachments: "NoOfAttachments"
            },
            NewItem: {
                MenuAction: "MenuAction",
                ToggleLeft:"ToggleLeft",
                ToggleRight:"ToggleRight",
            },
            Document: {
                DeleteDocuments: "DeleteDocuments",
                AddToBriefcase: "AddToBriefcase",
                RemoveFromBriefcase: "RemoveFromBriefcase",
                AddToNewItems: "AddToNewItems",
                RemoveFromNewItems: "RemoveFromNewItems",
                ViewDocument: "ViewDocument",
            },
            Agenda: {
                MenuAction: "MenuAction"
            }
        },
        ID: {
            Survey: {
                Open: "Open",
                Closed: "Closed",
                Own: "Own"
            },
            Approval: {
                Open: "Open",
                Closed: "Closed",
                Own: "Own"
            }
        }
    }
}
