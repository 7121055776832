function ComposeMessageController(bvSliderService, messagesService, $scope, modal, context, localizedMessages, imageService, bvDialogService, permissionService, presentationService, commonService, bvDialog, bvDialogMobile, templatesService, $filter, constants,legacyUserService){

	$scope.ui = { label : {
			attachment : localizedMessages.get("ATTACHMENT"),
			attachments : localizedMessages.get("ATTACHMENTS"),
			subject : localizedMessages.get("SUBJECT"),
			message : localizedMessages.get("MESSAGE"),
			secure : localizedMessages.get("SECURE"),
			notSecure : localizedMessages.get("NOT_SECURE"),
			send : localizedMessages.get("SEND"),
			recipentsTitle : localizedMessages.get("SEARCH_FOR_RECIPENTS"),
			addToBriefcase : localizedMessages.get("ADD_TO_BRIEFCASE"),
			addToNewItems : localizedMessages.get("ADD_TO_NEW_ITEMS"),
			to : localizedMessages.get("TO"),
			expiry : localizedMessages.get("EXPIRY"),
			permission : localizedMessages.get("PERMISSION"),
			own : localizedMessages.get("OWN"),
			collaborate : localizedMessages.get("COLLABORATE"),
			view : localizedMessages.get("VIEW"),
			deny : localizedMessages.get("DENY"),
			print : localizedMessages.get("PRINT"),
			current : localizedMessages.get("CURRENT"),
			permissionTooltipOwn: localizedMessages.get("PERMISSION_TOOLTIP_OWN"),
			permissionTooltipCollaborate: localizedMessages.get("PERMISSION_TOOLTIP_COLLABORATE"),
			permissionTooltipView: localizedMessages.get("PERMISSION_TOOLTIP_VIEW"),
			permissionTooltipPrint: localizedMessages.get("PERMISSION_TOOLTIP_PRINT"),
			permissionTooltipCurrent: localizedMessages.get("PERMISSION_TOOLTIP_CURRENT"),
			selectRecipents: localizedMessages.get("SELECT_RECIPIENTS"),
			typeSubject: localizedMessages.get("TYPE_SUBJECT"),
			indicates_req_field		:localizedMessages.get("INDICATES_REQUIRED_FIELD"),
			popupTitle : $scope.bvModalControllerInputs.popupTitle,
			on : localizedMessages.get("ON") + ' ',
			wrote : ' ' + localizedMessages.get("WROTE") + ':',
			space : ' ',
		}
	};

	var ALERT_APPROVAL = 'alert_approval';
	var ALERT_SURVEY = 'alert_survey';
	var ALERT_MEETING = 'alert_meeting';

	var removeUnsafejavascript=function()
	{
		var closeButton = document.querySelectorAll(".select2-search-choice-close");
		for (var i = 0; i < closeButton.length; i++) {
			closeButton[i].removeAttribute("href");
		}
	}


	$scope.bvcheckfalse = 'resources/assets/svgs/bvcheckfalse.svg';
	$scope.bvchecktrue = 'resources/assets/svgs/bvchecktrue.svg';
	$scope.plusIcon 		='resources/assets/svgs/plus_icon.svg';

	$scope.hideUsersButton=false;
	$scope.hideAttachmentsButton=false;

	$scope.mobileActionButton	= $scope.bvModalControllerInputs.mobileActionButton || false;
	$scope.mobileActionButtonImagePath = $scope.bvModalControllerInputs.mobileActionButtonImagePath || "";

	$scope.selectedObjectFromContextMenu = $scope.bvModalControllerInputs.businessObjects;
	$scope.messageType = $scope.bvModalControllerInputs.messageType;
	$scope.popupTitle = $scope.bvModalControllerInputs.popupTitle;
	if($scope.popupTitle == null || $scope.popupTitle == undefined) {
		$scope.popupTitle = localizedMessages.get("COMPOSE_NEW_MESSAGE");
	}
	setTimeout(function(){
		commonService.getUsers().then (function(data)
		{
			$scope.users = data;
			removeUnsafejavascript();
		});
	},0)

	$scope.permissions = [
		{label : localizedMessages.get("CURRENT"), value : "nothing"},
		{label : localizedMessages.get("VIEW"), value : "read"},
		{label : localizedMessages.get("PRINT"), value : "print"},
		{label : localizedMessages.get("COLLABORATE"), value : "share"},
		{label : localizedMessages.get("OWN"), value : "owner"}
	];

	$scope.selectedMessage = {
		sharedWithList: [],
		name: '',
		comments: '',
		attachments: [],
		createdBy: '',
		createdByBaseUuid: '',
		subjectRequired: false,
		recipentsRequired: false,
		newItemEnabled: false,
		briefcaseEnabled: false,
		secureEnabled: $scope.bvModalControllerInputs.secure ? true : false,
		permission: 'nothing',
		permissionLabel: localizedMessages.get("CURRENT"),
		fileNotIncluded : true,
		alwaysSecure: $scope.bvModalControllerInputs.alwaysSecure,
	};

	$scope.hideAttachmentIcon = false;
	$scope.hideAttachmentCloseButton = false;
	$scope.svgFillblue = '#2273a5';
	$scope.svgFillbluegrey = '#aeaeae';
	$scope.isProfile = context.getModel().portalVO.objectBaseUuid.slice(0, '0PRFL'.length) == '0PRFL';
	$scope.permissionAllowed = false;

	$scope.secureStyle = $scope.bvModalControllerInputs.alwaysSecure ? {"pointer-events": "none","opacity": "0.5"} : {};
	messagesService.isSecureEnabled().then(function(response)
	{
		$scope.secure = response.data;
	});

	var isFolder = function(node) {
		if(node.boName != null && node.boName != undefined && node.boName.toLowerCase() =="folderbo")
		{
			return true;
		}
		return false;
	}

	var checkFileIncluded = function() {
		$scope.selectedMessage.fileNotIncluded = true;

		if($scope.messageType.toLowerCase() == ALERT_APPROVAL || $scope.messageType.toLowerCase() == ALERT_SURVEY)
			return;

		for(var i=0; i<$scope.selectedMessage.attachments.length; i++) {
			if(!isFolder($scope.selectedMessage.attachments[i])) {
				$scope.selectedMessage.fileNotIncluded = false;
			}
		}
	}

	var checkFolderIncluded = function() {
		for(var i=0; i<$scope.selectedMessage.attachments.length; i++) {
			if(isFolder($scope.selectedMessage.attachments[i])) {
				return true;
			}
		}
		return false;
	}

	var checkPermissionAllowed = function(nodes) {

		if($scope.messageType.toLowerCase() == ALERT_APPROVAL || $scope.messageType.toLowerCase() == ALERT_SURVEY)
			return;

		$scope.notOwnPermission = false;
		for(var i=0; i<nodes.length; i++) {
//		  if(permissionService.isShareAllowed(nodes[i])) {
//			  $scope.permissionAllowed = true;
//		  }
			if(!permissionService.isOwner(nodes[i])) {
				$scope.notOwnPermission = true;
			} else {
				$scope.permissionAllowed = true;
			}
		}
		if($scope.notOwnPermission) {
			if($scope.permissions.length == 5) {
				$scope.permissions.splice(4, 1);
			}
		} else {
			if($scope.permissions.length < 5) {
				$scope.permissions.push({label : localizedMessages.get("OWN"), value : "owner"});
			}
		}
	}

	var isAttachmentIncluded = function(selectedNode) {
		for (var i=0; i<$scope.selectedMessage.attachments.length; i++) {
			if($scope.selectedMessage.attachments[i].objectBaseUuid == selectedNode.objectBaseUuid) {
				return true;
			}
		}
		return false;
	}

	var setupNodes = function(selectedNodes){
		var hasNotAllowedFile = false;
		var notAllowedFilesName = '';
		checkPermissionAllowed(selectedNodes);
		for(var i=0; i<selectedNodes.length; i++) {
			if(permissionService.isShareAllowed(selectedNodes[i]) || permissionService.isOwner(selectedNodes[i])) {
				if(!isAttachmentIncluded(selectedNodes[i]))
				{
					$scope.selectedMessage.attachments.push(selectedNodes[i]);
				}
			} else {
				hasNotAllowedFile = true;
				if(notAllowedFilesName == '') {
					notAllowedFilesName = notAllowedFilesName + selectedNodes[i].name;
				} else {
					notAllowedFilesName = notAllowedFilesName +', '+ selectedNodes[i].name;
				}

			}
		}
		if(hasNotAllowedFile) {
			var alertMsg = localizedMessages.get('NO_PERMISSION_MSG');
			bvDialog.showMessage(alertMsg);
		}
		checkFileIncluded();
		checkPermissionAllowed($scope.selectedMessage.attachments);
	}

	if($scope.messageType.toLowerCase() == 'forward' || $scope.messageType.toLowerCase() == 'reply' || $scope.messageType.toLowerCase() == 'replyall')
	{
		$scope.selectedMessage.name = $scope.bvModalControllerInputs.selectedMessage.name;
		$scope.selectedMessage.createdBy = $scope.bvModalControllerInputs.selectedMessage.createdBy;
		$scope.selectedMessage.createdByBaseUuid = $scope.bvModalControllerInputs.selectedMessage.createdByBaseUuid;
		$scope.selectedMessage.comments = '';
		$scope.selectedMessage.sharedWithList = angular.copy($scope.bvModalControllerInputs.selectedMessage.sharedWithList);
		$scope.selectedMessage.attachments = $scope.bvModalControllerInputs.selectedMessage.attachments;
		$scope.selectedMessage.objectBaseUuid = $scope.bvModalControllerInputs.selectedMessage.objectBaseUuid;

	}
	else if($scope.messageType.toLowerCase() == 'sent'){
		$scope.selectedMessage.name = $scope.bvModalControllerInputs.selectedMessage.name;
		$scope.selectedMessage.createdBy = $scope.bvModalControllerInputs.selectedMessage.createdBy;
		$scope.selectedMessage.createdByBaseUuid = $scope.bvModalControllerInputs.selectedMessage.createdByBaseUuid;
		$scope.selectedMessage.comments = $scope.bvModalControllerInputs.selectedMessage.comments;
		$scope.selectedMessage.sharedWithList = angular.copy($scope.bvModalControllerInputs.selectedMessage.sharedWithList);
		$scope.selectedMessage.attachments = $scope.bvModalControllerInputs.selectedMessage.attachments;
		$scope.selectedMessage.objectBaseUuid = $scope.bvModalControllerInputs.selectedMessage.objectBaseUuid;
	}
	else if($scope.messageType.toLowerCase() == ALERT_APPROVAL) {
		/* alert_approval */
		var inputs = $scope.bvModalControllerInputs;
		$scope.selectedMessage.sharedWithList = inputs.sharedWithList != undefined ? inputs.sharedWithList : [];
		$scope.selectedMessage.name = inputs.name != undefined ? inputs.name : "";
		$scope.selectedMessage.comments = inputs.comments != undefined ? inputs.comments : "";
		$scope.hideUsersButton=true;
		if(legacyUserService.isNasdaqUI)
		{
			$scope.selectedMessage.attachments.push($scope.selectedObjectFromContextMenu);
			$scope.hideAttachmentIcon = true;
			$scope.hideAttachmentCloseButton = true;
		}
		else{
			$scope.hideAttachmentsButton=true;
		}
	}
	else if($scope.messageType.toLowerCase() == ALERT_SURVEY) {
		/* alert_survey */
		var inputs = $scope.bvModalControllerInputs;
		$scope.selectedMessage.sharedWithList = inputs.sharedWithList != undefined ? inputs.sharedWithList : [];
		$scope.selectedMessage.name = inputs.name != undefined ? inputs.name : "";
		$scope.selectedMessage.comments = inputs.comments != undefined ? inputs.comments : "";
		$scope.hideUsersButton=true;
		if(legacyUserService.isNasdaqUI)
		{
			$scope.selectedMessage.attachments.push($scope.selectedObjectFromContextMenu);
			$scope.hideAttachmentIcon = true;
			$scope.hideAttachmentCloseButton = true;
		}
		else{
			$scope.hideAttachmentsButton=true;
		}
	}
	else if($scope.messageType.toLowerCase() == ALERT_MEETING) {
		/* alert_meeting */
		var inputs = $scope.bvModalControllerInputs;
		$scope.selectedMessage.sharedWithList = inputs.sharedWithList != undefined ? inputs.sharedWithList : [];
		$scope.selectedMessage.name = inputs.name != undefined ? inputs.name : "";
		$scope.selectedMessage.comments = inputs.comments != undefined ? inputs.comments : "";
		$scope.hideUsersButton=true;
		$scope.hideAttachmentsButton=true;
	}
	else
	{
		$scope.messageType = 'compose';
		var inputs = $scope.bvModalControllerInputs;
		$scope.selectedMessage.sharedWithList = inputs.sharedWithList != undefined ? inputs.sharedWithList : [];
		if($scope.selectedObjectFromContextMenu != undefined || $scope.selectedObjectFromContextMenu != null)
		{
			if($scope.selectedObjectFromContextMenu instanceof Array)
			{
				setupNodes($scope.selectedObjectFromContextMenu);
			}
			else
			{
				/*if($scope.selectedObjectFromContextMenu.isPdfACompliant!=null && $scope.selectedObjectFromContextMenu.isPdfACompliant=="false")
				{
					console.log("Non-compliant file has not been attached.")
				}
				else*/{
				$scope.selectedMessage.attachments.push($scope.selectedObjectFromContextMenu);
			}
			}
		}

	}

	checkFileIncluded();

	if($scope.messageType != 'compose'){

		if($scope.messageType=='forward') {
			$scope.selectedMessage.sharedWithList = [];
			// $scope.selectedMessage.attachments = [];
		} else {
			var createdByObj = new Object();
			createdByObj.uuid = $scope.selectedMessage.createdByBaseUuid;
			createdByObj.name = $scope.selectedMessage.createdBy;
			createdByObj.displayName = $scope.selectedMessage.createdBy;
			if($scope.messageType.toLowerCase() == 'reply') {
				$scope.selectedMessage.sharedWithList = [];
				$scope.selectedMessage.sharedWithList.push(createdByObj);
			} else if ($scope.messageType.toLowerCase() == 'replyall') {
				var isCreatorinclude = false;
				for (var i=0; i<$scope.selectedMessage.sharedWithList.length;i++) {
					if($scope.selectedMessage.sharedWithList[i].objectBaseUuid == $scope.selectedMessage.createdByBaseUuid){
						isCreatorinclude = true;
					}
				}
				if(!isCreatorinclude) {
					$scope.selectedMessage.sharedWithList.push(createdByObj);
				}
			}
		}
		if($scope.messageType != 'sent')
		{
			if($scope.messageType.toLowerCase() == 'forward') {
				if($scope.selectedMessage.name.substring(0,5) != localizedMessages.get("FWD")) {
					if($scope.selectedMessage.name.substring(0,4) == localizedMessages.get("RE")) {
						$scope.selectedMessage.name = $scope.selectedMessage.name.slice(4,$scope.selectedMessage.name.length);
					}
					$scope.selectedMessage.name = localizedMessages.get("FWD") + $scope.selectedMessage.name;
				}
			}
			else if($scope.messageType.toLowerCase() == ALERT_APPROVAL || $scope.messageType.toLowerCase() == ALERT_SURVEY) {
				// Do nothing on subject
			}
			else {
				if($scope.selectedMessage.name.substring(0,5) == localizedMessages.get("FWD")) {
					$scope.selectedMessage.name = $scope.selectedMessage.name.slice(5,$scope.selectedMessage.name.length);
				}

				if($scope.selectedMessage.name.substring(0,4) != localizedMessages.get("RE")) {
					$scope.selectedMessage.name = localizedMessages.get("RE") + $scope.selectedMessage.name;
				}
			}
		}

	} else {

		// for assigning default message name for share new item
//      if($scope.selectedMessage.name == '') {
//        for(i in $scope.selectedMessage.attachments) {
//	          if($scope.selectedMessage.name != '') $scope.selectedMessage.name += ',';
//	          $scope.selectedMessage.name += $scope.selectedMessage.attachments[i].name;
//        }
//      }
	}

	var resetData = function() {
		$scope.selectedMessage.sharedWithList = [];
		$scope.selectedMessage.name = '';
		$scope.selectedMessage.comments = '';
		$scope.selectedMessage.attachments = [];
		$scope.selectedMessage.createdBy = '';
		$scope.selectedMessage.createdByBaseUuid = '';
		$scope.selectedMessage.addToNewItems = false;
	};

	checkPermissionAllowed($scope.selectedMessage.attachments);

	$scope.cancel = function(result)
	{
		resetData();
		$scope.bvModalControllerInputs.bvModal.close();
		$scope.selectedMessage = null;
		$scope.messageType = undefined;
		if($scope.selectedObjectFromContextMenu && $scope.selectedObjectFromContextMenu.isPresentMode)
		{
			presentationService.manageBackDrop();
		}
	};

	$scope.close = function(result) {
		//angular.element('.bv-inbox-inputFile')[0].focus();
		bvDialog.confirm( localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED'), $scope.cancelEdit );
	};

	$scope.cancelConfirmation = function() {
		bvDialog.confirm( localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED'), $scope.cancelEdit );
	}

	var closeWithoutConfirm = function(result) {
		$scope.bvModalControllerInputs.bvModal.close();
		resetData();
		$scope.selectedMessage = null;
		$scope.messageType = undefined;
	};

	$scope.enableSecureOption = function(action) {
		if($scope.selectedMessage.alwaysSecure) {
			$scope.selectedMessage.secureEnabled = true;
		}
		else {
			$scope.selectedMessage.secureEnabled = action;
		}

	};

	$scope.onSelect = function(selectedUser) {
		for(var i=0; i<$scope.selectedMessage.sharedWithList.length;i++) {
			if($scope.selectedMessage.sharedWithList[i].uuid == selectedUser.objectBaseUuid || $scope.selectedMessage.sharedWithList[i].objectBaseUuid == selectedUser.objectBaseUuid) {
				return;
			}
		}
		$scope.selectedMessage.sharedWithList.push(selectedUser);
		$scope.selectedMessage.recipentsRequired = false;
	};

	$scope.removeRecipent = function(index, selectedRecipent) {
		$scope.selectedMessage.sharedWithList.splice(index,1);
		if($scope.selectedMessage.sharedWithList.length==0) {
			$scope.selectedMessage.recipentsRequired = true;
		}
	};

	$scope.removeAttachment = function(index, selectedAttachment) {
		$scope.selectedMessage.attachments.splice(index,1);
		var hasDocument = false;
		for(var i=0; i<$scope.selectedMessage.attachments.length; i++) {
			if($scope.selectedMessage.attachments[i].boName.toLowerCase() =="documentbo") {
				hasDocument = true;
			}
		}
		if(!hasDocument)
		{
			$scope.selectedMessage.newItemEnabled = false;
			$scope.selectedMessage.briefcaseEnabled = false;
		}
		$scope.permissionAllowed = false;
		checkPermissionAllowed($scope.selectedMessage.attachments);
		checkFileIncluded();

	};


////// new recepient selection

	$scope.grouping = true;
	$scope.multiSelect = true;

//////


	$scope.onSelectRecipent = function(selectedUsers) {
		for(var i in selectedUsers)
		{
			$scope.onSelect(selectedUsers[i]);
		}

		setTimeout(function(){
			removeUnsafejavascript();
		}, 1000);

	};

	var showRecipents = function() {
		unfocusEditArea();
		$scope.unshadowOuterDiv();
		templatesService.setCustomDialogTemplate();
		$scope.recipentPopupTitle = localizedMessages.get("RECIPIENTS");
		if(MobileUtil.isAndroid() || MobileUtil.isIPhone()){
			//bvDialogMobile.itemListSelection($scope.recipentPopupTitle, $scope.users, $scope.onSelectRecipent, $scope.multiSelect, $scope.grouping, false );
			bvDialogMobile.itemListSelection($scope.recipentPopupTitle, true, $scope.onSelectRecipent);
		}else{
			bvDialog.itemListSelection($scope.recipentPopupTitle, true, $scope.onSelectRecipent);
		}
	}
	$scope.openAttachmentChooser = function()
	{
		openFileChooser();
	};

	var openFileChooser = function()
	{
		var request = bvDialog.attachDocument(setupNodes);
	};


	$scope.selectedFiles = function(selectedNodes) {
		setupNodes(selectedNodes);
		$scope.attachmentListModal.dismiss('close');
	}

	$scope.closeChildDialog = function()
	{
//    	$modalInstance.dismiss('cancel');
	};
	$scope.closeNgDialog = function()
	{
		$scope.closeThisDialog('');
	}

	$scope.enableNewItem = function()
	{
		$scope.selectedMessage.newItemEnabled = !$scope.selectedMessage.newItemEnabled;
	}
	$scope.enableBriefcase = function()
	{
		$scope.selectedMessage.briefcaseEnabled = !$scope.selectedMessage.briefcaseEnabled;
	}
	$scope.enablePermission = function()
	{
		$scope.selectedMessage.permissionEnabled = !$scope.selectedMessage.permissionEnabled;

	}


	///////Attachment thumbnails ////

	$scope.getDocumentThumbnail = function(attachment) {
		return imageService.getImageForObject(attachment);
	}
	///////////////

	//////// Shard with grid //////

	$scope.getIconForType = function (item)
	{
		return imageService.getImageByExtension(item.type);
	}

	$scope.removeFromSharedWith = function(item) {
		$scope.removeRecipent($scope.selectedMessage.sharedWithList.indexOf(item), item);
	}

	$scope.sharedWithColumns = [
		{displayName: '', field:'', width: 27,
			cellTemplate: "<div> <img style='width:23px; height:25px; margin-left:2px; vertical-align: -webkit-baseline-middle;' src='{{getIconForType(row.entity)}}'></div>"
		},
		{displayName:'Name', field:'name'},
		{displayName: '', field:'', width: 25,
			cellTemplate: "<div style='text-align: center;'> <span ng-click='removeFromSharedWith(row.entity)' style='vertical-align: -webkit-baseline-middle;font-weight: 500; font-size: 18px;'> X </span> </div>"
		}
	];

	$scope.sharedWithGridOptions = {
		columnDefs: $scope.sharedWithColumns,
		data: 'selectedMessage.sharedWithList',
		enableRowSelection: false
	};
	///////////////////////////////

	$scope.permissionChange = function(permission)
	{
		$scope.selectedMessage.permissionLabel = permission.label;
		$scope.selectedMessage.permission = permission.value;
	}

	$scope.selectedMessage.expiryList = messagesService.getExpiryOptions();
	$scope.selectedMessage.selectedExpiry = $scope.selectedMessage.expiryList[0];

	$scope.previewAttachment = function(docId,docObject) {
		bvDialog.showDocumentPreview(docId,docObject);
	}

	var buttons = $scope.bvModalControllerInputs.buttons;



	if(buttons) buttons[0].name = localizedMessages.get('CANCEL');
	if(buttons) buttons[1].name = localizedMessages.get('SEND');

	if(buttons) buttons[0].action = function(){
		bvDialog.confirm( localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED'), $scope.cancelEdit );
	}
	$scope.bvModalControllerInputs.closeButton = function() {
		bvDialog.confirm( localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED'), $scope.cancelEdit );
	};
	$scope.cancelEdit = function(){
		$scope.bvModalControllerInputs.bvModal.close();
		$scope.selectedMessage = null;
		$scope.messageType = undefined;
		if($scope.selectedObjectFromContextMenu && $scope.selectedObjectFromContextMenu.isPresentMode)
		{
			presentationService.manageBackDrop();
		}
	}

	var notify = function() {
		var toSecurityUuidList = '';
		var attachmentUuidList = '';
		var filesUuidList = '';

		for (var i=0; i<$scope.selectedMessage.sharedWithList.length;i++)
		{
			if($scope.selectedMessage.sharedWithList[i].objectBaseUuid != undefined)
			{
				toSecurityUuidList = toSecurityUuidList+'#'+$scope.selectedMessage.sharedWithList[i].objectBaseUuid;
			}
			else if ($scope.selectedMessage.sharedWithList[i].uuid != undefined)
			{
				toSecurityUuidList = toSecurityUuidList+'#'+$scope.selectedMessage.sharedWithList[i].uuid;
			}

		}

		for (var i=0; i<$scope.selectedMessage.attachments.length; i++)
		{
			var attachedObject = $scope.selectedMessage.attachments[i];
			attachmentUuidList = attachmentUuidList+'#'+attachedObject.objectBaseUuid;

			if(!StringUtils.isNullEmpty(attachedObject.extensionType))
			{
				attachedObject.boName = "documentbo";
			}

			if(StringUtils.isApprovalPollQuestion(attachedObject.objectBaseUuid) ||StringUtils.isSurvey(attachedObject.objectBaseUuid) ||
				attachedObject.boName.toLowerCase() == "documentbo") {
				filesUuidList = filesUuidList+'#'+$scope.selectedMessage.attachments[i].objectBaseUuid;
			}
		}
		if(!$scope.permissionAllowed)
		{
			$scope.selectedMessage.permission = "Read";
		}

		var name = $scope.selectedMessage.name;
		var comments = $scope.selectedMessage.comments != undefined && $scope.selectedMessage.comments != null ?
			$scope.selectedMessage.comments.replace(/\r\n|\r|\n/g,"<br />") :
			$scope.selectedMessage.comments;

		if($scope.messageType.toLowerCase() == 'reply' || $scope.messageType.toLowerCase() == 'replyall' || $scope.messageType.toLowerCase() == 'forward')
		{
			messagesService.replyShareContainerJSON($scope.selectedMessage.objectBaseUuid, toSecurityUuidList.substring(1,toSecurityUuidList.length), name, comments, attachmentUuidList.substring(1,attachmentUuidList.length), $scope.selectedMessage.permission, $scope.selectedMessage.secureEnabled, $scope.selectedMessage.newItemEnabled, $scope.selectedMessage.briefcaseEnabled, filesUuidList.substring(1,filesUuidList.length)).then(function(response) {
				var messagesController = angular.element(document.querySelector('[messages-controller]')).scope();
				if(messagesController){
					if(messagesController.tabs[1].active)
						messagesController.getSentJSON(true, true);
				}
			});

		}
		else
		{
			messagesService.composeMessageJSON(toSecurityUuidList.substring(1,toSecurityUuidList.length), name, comments, attachmentUuidList.substring(1,attachmentUuidList.length), $scope.selectedMessage.permission, $scope.selectedMessage.secureEnabled, $scope.selectedMessage.newItemEnabled,
				$scope.selectedMessage.briefcaseEnabled, filesUuidList.substring(1,filesUuidList.length), undefined, $scope.manualAlert).then(function(response) {
				var messagesController = angular.element(document.querySelector('[messages-controller]')).scope();
				if(messagesController){
					if((!MobileUtil.isMobileClient() && messagesController.tabs[1].active) ||
						(MobileUtil.isMobileClient() && !messagesController.inboxActive))
						messagesController.getSentJSON(true, true);
				}
			});
		}
		closeWithoutConfirm();
	}

	$scope.$watch('selectedMessage.sharedWithList', function() {
		setTimeout(function(){
			removeUnsafejavascript();
		}, 1000);
	});

	var sendMessage = function(){
		if($scope.selectedMessage.sharedWithList.length==0)
		{
			$scope.selectedMessage.recipentsRequired = true;
			var alertMsg = localizedMessages.get('NO_RECIPIENT_MSG');
			bvDialog.showMessage(alertMsg);
			return;
		}
		if($scope.selectedMessage.name == undefined || $scope.selectedMessage.name == '')
		{
			$scope.selectedMessage.subjectRequired = true;
			var alertMsg = localizedMessages.get('NO_SUBJECT_MSG');
			bvDialog.showMessage(alertMsg);
			return;
		}
		if($scope.selectedMessage.comments.length > 20000) {
			var alertMsg = localizedMessages.get('MSG_COMPOSE_MESSAGE_CONTENT_EXCEED_LIMIT');
			bvDialog.showMessage(alertMsg);
			return;
		}

		if($scope.selectedMessage.newItemEnabled && checkFolderIncluded()) {
			bvDialog.confirm( localizedMessages.get('MSG_ADD_TO_NEWITEM_INCLUDE_FOLDER'), notify);
		} else {
			notify();
		}
	}

	if(buttons) buttons[1].action = sendMessage;
	$scope.action = function() {
		if (MobileUtil.isMobileClient()) {
			sendMessage();
		}
	}

	if($scope.messageType === ALERT_APPROVAL || $scope.messageType === ALERT_SURVEY) {
		// don't need users and attachment buttons
		buttons.splice(2, 1);
		buttons.splice(2, 1);

		// for disabling to field
		$scope.disabled = true;
	}

	$scope.shadowOuterDiv = function() {
		var commentOuter = document.getElementById("commentOuter");
		if(commentOuter)
			commentOuter.style.boxShadow = 'inset 0px 0px 0px 3px #99b2cc';
	}
	$scope.shadowOuterDiv1 = function() {
		var outer = document.getElementById("outer");
		if(outer)
			outer.style.boxShadow = 'inset 0px 0px 0px 3px #99b2cc';
	}
	$scope.unshadowOuterDiv = function() {
		var commentOuter = document.getElementById("commentOuter");
		if(commentOuter)
			commentOuter.style.boxShadow = 'none';
	}
	$scope.unshadowOuterDiv1 = function() {
		var outer = document.getElementById("outer");
		if(outer)
			outer.style.boxShadow = 'none';
	}

	$scope.documentSortableOptions = {
		handle: '> .drag-handle',
		update: function(e, ui)
		{
			//console.log(ui.item);
		}
	};

	$scope.inboxSliderOptions = {
		items: [],
		slideThrough: 1,
		shownItems: 3,
		smallButtons: MobileUtil.isAndroid() || MobileUtil.isIPhone() || MobileUtil.isSurface() ? false : true,
		fixQuantity: false
	}

	$scope.inboxSliderOptions.items = $scope.selectedMessage.attachments;

	setTimeout(function(){
		bvSliderService.fixScroll();
		bvSliderService.fixQuantity();
		bvSliderService.fixButtons();
	}, 500);

	$scope.showRecipents = showRecipents;
	$scope.addAttachments = function(){
		unfocusEditArea();
		$scope.unshadowOuterDiv();
		if(MobileUtil.isAndroid() || MobileUtil.isIPhone()){
			bvDialogMobile.attachDocument(setupNodes, false);
		}
		else{
			bvDialog.attachDocument(setupNodes,true);
		}

	}
	$scope.getLocalizedDate = function(date) {
		return moment($filter('date')(date, 'medium')).format('lll');
	}

	function unfocusEditArea(){
		document.querySelector('.bv-inbox-inputFile').focus();
		document.querySelector('.bv-inbox-inputFile').blur();
	}
}

function ContentEditable(){
	return {
		require: 'ngModel',
		link: function(scope, element, attrs, ctrl) {
			// view -> model
			element.bind('blur', function() {
				scope.$apply(function() {
					ctrl.$setViewValue(element.html());
				});
			});

			// model -> view
			ctrl.$render = function() {
				element.html(ctrl.$viewValue);
			};

			// load init value from DOM
			ctrl.$render();
		}
	};
}

angular
	.module('composeMessage', ['messages.service','image.service', 'ui.select','ngSanitize','ui.sortable', 'constants.service'])
	.directive('contenteditable', ContentEditable)
	.controller('ComposeMessageController', ['bvSliderService','messagesService','$scope','$modal','context','localizedMessages','imageService','bvDialogService', 'permissionService', 'presentationService', 'commonService','bvDialog', 'bvDialogMobile', 'templatesService','$filter', 'constants','legacyUserService', ComposeMessageController])
	.filter('unsafe',function($sce) {
		return function(text) {
			return $sce.trustAsHtml(text);
		};
	})
	.filter('recipientFilter', function() {
		return function(items, props) {
			var out = [];

			if (angular.isArray(items)) {
				items.forEach(function(item) {
					var itemMatches = false;

					var keys = Object.keys(props);
					for (var i = 0; i < keys.length; i++) {
						var prop = keys[i];
						var text = props[prop].toLowerCase();
						if (item[prop].toString().toLowerCase().indexOf(text) == 0) {
							itemMatches = true;
							break;
						}
					}

					if (itemMatches) {
						out.push(item);
					}
				});
			} else {
				// Let the output be the input untouched
				out = items;
			}

			return out;
		};
	});
