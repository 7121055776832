loadContext.$inject = ['ajaxService', 'context', '$rootScope', 'legacyUserService', '$state'];

function loadContext(ajaxService, context, $rootScope, legacyUserService, $state){
	var service = {
			loadContext : function() {
				if(context.isReady()) {
					if(window.console) console.log("Context is ready !!!");
					return context;
				}
				else
				{
                    // Remove the WalkMe if it has been already loaded
					if (window._walkMe !== undefined) {
                        window._walkMe.removeWalkMe()
                        console.log(">>>>>>>>> walkme - WalkMe removed");
					}

					var request = ajaxService.getPortalData();
					var encryptionKeyError = null;
					return request.then(function(data) {
						if(data !== null)
						{	
                            if (data.companyVO != undefined && data.companyVO != null && data.userVO != undefined && data.userVO != null) {
								window.walkMeSettings = [];
								window.walkMeSettings.uuid = data.userVO.externalId;
								window.walkMeSettings.onboarding = data.companyVO.onboarding;
								
								const walkmeEnabledForCustomer = data.companyVO.enableWalkMeTutorials;
								const walkmeCDNUrl = data.companyVO.walkMeSnippetCDN;

								console.log(">>>>>>>>> walkme - walkmeEnabledForCustomer", walkmeEnabledForCustomer);
								console.log(">>>>>>>>> walkme - walkMeSnippetCDN", walkmeCDNUrl);
								if (walkmeEnabledForCustomer) {
									// Load WalkMe script
									var walkme = document.createElement('script');
									walkme.type = 'text/javascript';
									walkme.async = true;
                                    walkme.src = walkmeCDNUrl;
									var s = document.getElementsByTagName('script')[0];
									s.parentNode.insertBefore(walkme, s);
									window._walkmeConfig = {smartLoad:true};
									console.log(">>>>>>>>> walkme - WalkMe added");
								}
							}

							MobileUtil.setUserSessionTimeout(data.userSessionTimeoutMinutes);
							
							var encryptionKeyError = null;						

							bvUsageUtils.addToGlobalUsageMap(bvUsageUtils.key.CompanyShortName, (data.companyVO ? data.companyVO.companyShortName : null));
							bvUsageUtils.addToGlobalUsageMap(bvUsageUtils.key.CompanyUUID, ((data.companyVO && data.companyVO.objectBaseUuid) ? data.companyVO.objectBaseUuid.substring(6,12) : null));
							bvUsageUtils.addToGlobalUsageMap(bvUsageUtils.key.n3aUserName, (data.userVO ? data.userVO.loginID : null));
							bvUsageUtils.addToGlobalUsageMap(bvUsageUtils.key.userActivityID, (data.activityTrackingId ? data.activityTrackingId : null));
							window.userActivityIdForHeader = (data.activityTrackingId ? data.activityTrackingId : null); //Add ActivityId to request Header, used to link EUM and APM data

							if(data.cmkNSet != undefined && data.cmkNSet == true)
							{								
								
								if(data.companyVO != undefined && data.companyVO != null)
								{
									encryptionKeyError = 'requireEncryptionKeyForAdmin';
									data.portalVO = {};									
									context.homeModel.companyBaseUuid = data.companyVO.objectBaseUuid;
									
									if(context.homeModel.serverPublicKey == undefined  && data.cryptoVO != null)
									{
										if(data.cryptoVO.publicCertificate && data.cryptoVO.publicCertificate!="")
											{
												if(data.cryptoVO.publicCertificate.indexOf("-----BEGIN CERTIFICATE-----")==-1)
												{
													data.cryptoVO.publicCertificate="-----BEGIN CERTIFICATE-----  ".concat(data.cryptoVO.publicCertificate,' -----END CERTIFICATE-----')
												}
												context.homeModel.serverPublicKey = X509.getPublicKeyFromCertPEM(data.cryptoVO.publicCertificate);								
										
											}
										}
								}
								else{
									encryptionKeyError = 'requireEncryptionKeyForUser';
									data.portalVO = {};
									data.companyVO = {};
									
									if(data.isN3aLogin){
										$rootScope.encryptionKeyError = 'requireEncryptionKeyForUser';
									}
								}
              }
              
							context.setModel(data);
							
							legacyUserService.setNasdaqUI(data.companyVO.enableNasdaqUI);
							
							$rootScope.SELECTED_PORTAL_BASEUUID = data.portalVO.objectBaseUuid;
							
							context.setEncryptionKeyError(encryptionKeyError);
							if(window.console) console.log("Context is ready !!!");
							//Navigate only if default landing module is obtained.
							if(data.html5LandingModule != undefined && data.html5LandingModule != null ){								
								console.log('html5LandingModule: ' + data.html5LandingModule.toLowerCase().replace(" ", ""));
								var defaultLandingModule = data.html5LandingModule.toLowerCase().replace(" ", "");
								var defaultJupLandingModule = data.jupHtml5LandingModule.toLowerCase().replace(" ", "");
								$state.go('home.' + (MobileUtil.isApp() ? defaultJupLandingModule : defaultLandingModule), null, { reload: true });
							}
							
							if(data.isN3aLogin){
								$rootScope.isN3AUser = data.isN3aLogin;
							}

						    return context;
					    }

                        if(window.console) console.log("Failed to load Context !!!");
                            return null;

                    }, function(err) {
                        if(err && err.headers && typeof err.headers == 'function' && err.headers().isn3alogin && err.headers().isn3alogin === 'true'){
                            $rootScope.isN3AUser = true;
                            $state.go('login.auth', {}, { reload: true });
                        }

                        if(window.console) console.log("403: Failed to load Context !!!");
                        return null;
                    });
				}
			},
			setUrlRedirectStr : function(str){
				context.setUrlRedirectStr(str);
			},
			getUrlRedirectStr : function(){
				return context.getUrlRedirectStr();
			},
			getEnabledFeatures : function() {
				var request = ajaxService.getEnabledFeatures();
				return request.then(function(data) {
					if (data !== null) {
						context.setEnabledFeatures(data);
					}
				}, function(err) {
					if(window.console) console.log("500: Failed to enabled features json");
				});
			}

	};
	return service;
}