CalendarController.$inject = ['$scope', '$rootScope', '$state', '$locale', '$stateParams', 'calendarService', 'commonService', 'presentationService', 'localizedMessages', 'ipadService', '$modal', '$templateCache', 'context','modelService','bvDialogService','permissionsService', 'bvSliderInstanceService', '$timeout', 'bvDialog', 'bvDialogMobile', 'homeService', 'legacyUserService', 'reactInterface','$compile','eventsdialogService', 'toppanelService', 'teamService'];
function CalendarController($scope, $rootScope, $state, $locale, $stateParams, calendarService, commonService, presentationService, localizedMessages, ipadService, $modal, $templateCache, context, modelService, bvDialogService, permissionsService, bvSliderInstanceService, $timeout, bvDialog, bvDialogMobile, homeService, legacyUserService, reactInterface,$compile,eventsdialogService, toppanelService, teamService){

	var ctrlName = 'CalendarController';
	var currentState = $state.$current.name;
	$scope.$parent.currentState.value = currentState;
	$scope.reactInterface = reactInterface;
	$scope.evenLimitTextName = localizedMessages.get('MORE');
	//$scope.meetingCards = true;
	/*var clickCards = function()
	{
		$rootScope.SELECTED_TAB_FROM_CONF = localizedMessages.get('DAILY');
		ipadService.touchContent(false);
		ipadService.touchOnscroll();
		$scope.showNewMeetingDialog = false;
		$state.go('home.calendar');
	}

	var clickTimeLine = function()
	{
		$rootScope.SELECTED_TAB_FROM_CONF = localizedMessages.get('YEARLY');
    	ipadService.touchContent(true);
    	$scope.showNewMeetingDialog = false;
    	$state.go('home.calendar');
	}

	var clickMonthly = function()
	{
		$rootScope.SELECTED_TAB_FROM_CONF = localizedMessages.get('MONTHLY');
		ipadService.touchContent(false);
		$scope.showNewMeetingDialog = false;
		$state.go('home.calendar');
	}*/
	
	$scope.eventsF = function (startTime, endTIme, timezone, callback) {
		var loc = moment.locale();
        moment.locale('en-us');
        var start = moment(startTime).locale('en')
        var end = moment(endTIme).locale('en')
		var startDayOfYear = parseInt(StringUtils.momentL(start).format('DDDD'));
		var endDayOfYear = parseInt(StringUtils.momentL(end).format('DDDD'));
		var allEvents = calendarService.getAllCalendarEvents(false, allEvents => {
			var allDayEvents = allEvents.listCalendarEvents;
			var filteredEvents = [];
			var m = StringUtils.momentL;
			var currDay = m(start);
			var meetingNames = [];
			var meetingIds = [];
			if(startDayOfYear < endDayOfYear){
				for (var i = startDayOfYear; i <= endDayOfYear; i++) { 
					if(allDayEvents[currDay.format('ll')]){
						for(var l=0; l<allDayEvents[currDay.format('ll')].length; l++){
							var dayEvent = allDayEvents[currDay.format('ll')][l];
							if(meetingIds.indexOf(dayEvent.objectBaseUuid) == -1){
								meetingIds.push(dayEvent.objectBaseUuid);
								meetingNames.push(dayEvent.name);
								filteredEvents.push(dayEvent);  
							}
						}
					}
					currDay = currDay.add(1,'d');
				}
			}else{
				for (var i = startDayOfYear; i <=365; i++) { 
					if(allDayEvents[currDay.format('ll')]){
						for(var l=0; l<allDayEvents[currDay.format('ll')].length; l++){
							var dayEvent = allDayEvents[currDay.format('ll')][l];
							if(meetingIds.indexOf(dayEvent.objectBaseUuid) == -1){
								meetingIds.push(dayEvent.objectBaseUuid);
								meetingNames.push(dayEvent.name);
								filteredEvents.push(dayEvent);  
							}
						}
						
					}
					currDay = currDay.add(1,'d');
				}
				
				for (var i = 1; i <=endDayOfYear; i++) { 
					if(allDayEvents[currDay.format('ll')]){
						for(var l=0; l<allDayEvents[currDay.format('ll')].length; l++){
							var dayEvent = allDayEvents[currDay.format('ll')][l];
							if(meetingIds.indexOf(dayEvent.objectBaseUuid) == -1){
								meetingIds.push(dayEvent.objectBaseUuid);
								meetingNames.push(dayEvent.name);
								filteredEvents.push(dayEvent);  
							}
						}
					}
					currDay = currDay.add(1,'d');
				}
			}
			callback(filteredEvents);
		});
		moment.locale(loc);
	};
	    
    /* alert on eventClick */
    var alertOnEventClick = function( dateTime, jsEvent, view){
    	var loc = moment.locale();
        moment.locale('en-us');
        var date = moment(dateTime).locale('en')
    	//var day = moment(date.start);
    	var day = moment(date);
		var currentDay = {
			title : day.format("DDDD"),
			day : day.format("ll"),
			current : true,
			currentDay : day.format("ll") == StringUtils.momentL().format("ll"),
			clicked : !1
		}
    	
    	var startDayOfYear = parseInt(StringUtils.momentL(date.start).format('DDDD'));
    	var allEvents = calendarService.getAllCalendarEvents();
    	var dayEvents = allEvents.listCalendarEvents[currentDay.day];
        //$scope.alertMessage = (date.title + ' was clicked ');
    	if(dayEvents && dayEvents.length > 0){
				var currentUser = context.getModel().userVO;
				teamService.getUserDataBybaseUuid(currentUser.objectBaseUuid).then(function(data) {
					eventsdialogService.showDialog(dayEvents, day, jsEvent, currentDay.day, data.timeFormat);
				});
            
        day.clicked = true;
      }
    	moment.locale(loc);
    };
    /* alert on Drop */
     $scope.alertOnDrop = function(event, delta, revertFunc, jsEvent, ui, view){
       $scope.alertMessage = ('Event Droped to make dayDelta ' + delta);
    };
    /* alert on Resize */
    $scope.alertOnResize = function(event, delta, revertFunc, jsEvent, ui, view ){
       $scope.alertMessage = ('Event Resized to make dayDelta ' + delta);
    };
    $scope.eventRender = function( event, element, view ) { 
        element.attr({'tooltip': event.title,
                     'tooltip-append-to-body': true});
//        element.bind('click', function(event){
//        	console.log('***Single click***'+event);
//        });
        $compile(element)($scope);
    };
    
//    $scope.renderView = function(view){    
//        var date = new Date(view.calendar.getDate());
//        $scope.currentDate = date.toDateString();
//        $scope.$apply(function(){
//          //$scope.alertMessage = ('Page render with date '+ $scope.currentDate);
//        	console.log('Page render with date '+ $scope.currentDate);
//        });
//    };
	   /* $scope.dayRender = function(date, element, view) {
	     var dateParam = date;
	     var elementParam = element;
	     var viewParam = view;
		 element.bind('dblclick', function(event) {
			 console.log('double click!!!'+event);
			 console.log('date Param'+dateParam);
			 var currentDate = event.currentTarget.dataset.date;
			 if(currentDate){
				 var day = moment(currentDate);
				 var currentDay = {
						title : day.format("D"),
						day : day.format("ll"),
						current : true,
						currentDay : day.format("ll") == StringUtils.momentL().format("ll"),
						clicked : !1
					}
				 newMeeting(currentDay);
			 }
		 });
		 element.bind('click', function(event) {
			 
			 console.log('Single click***'+event);
			 var currentDate = event.currentTarget.dataset.date;
			 if(currentDate){
				 alertOnEventClick(dateParam, event, viewParam);
			 }
		 });
	}*/
    
    var DELAY = 250, clicks = 0, timer = null;
    
    $scope.dayRender = function(date, element, view) {
	     var dateParam = date;
	     var elementParam = element;
	     var viewParam = view;
//	     var isAllowed = calendarService.isCurrentUserAllowedToCreateModule();
	     element.on("click", function(event){

	         clicks++;  //count clicks

	         if(clicks === 1) {

	             timer = setTimeout(function() {

//	            	 console.log('Single click***'+event);
	    			 var currentDate = event.currentTarget.dataset.date;
	    			 if(currentDate){
	    				 alertOnEventClick(dateParam, event, viewParam);
	    			 }
	                 //alert("Single Click");  //perform single-click action    
	                clicks = 0;             //after action performed, reset counter

	             }, DELAY);

	         } else {

	             clearTimeout(timer);    //prevent single-click action
	             var currentDate = event.currentTarget.dataset.date;
	             var currentDate = moment(currentDate).locale('en');
	            if(calendarService.isCurrentUserAllowedToCreateModule()){
	            	if(currentDate && !MobileUtil.isMobileClient()){
						 var day = moment(currentDate);
						 var currentDay = {
								title : day.format("D"),
								day : StringUtils.momentL(currentDate).format("ll"),
								current : true,
								currentDay : StringUtils.momentL(currentDate).format("ll") == StringUtils.momentL().format("ll"),
								clicked : !1
							}
						 newMeeting(currentDay);
					 }
	            }
	             //alert("Double Click");  //perform double-click action
	             clicks = 0;             //after action performed, reset counter
	         }

	     })
	     .on("dblclick", function(e){
	         e.preventDefault();  //cancel system double-click event
	     });
	     
    }
    
    $scope.initialLocaleCode = localStorage.getItem('USER_LANG_PREF').toLowerCase(); 
    if($scope.initialLocaleCode == 'zh-cn'){
   	 $scope.localeWithoutRegionCode = localStorage.getItem('USER_LANG_PREF').toLowerCase();
   }else{
   	 $scope.localeWithoutRegionCode = localStorage.getItem('USER_LANG_PREF').toLowerCase().split(/[_-]+/)[0];
   }
    
    /* config object */
    $scope.uiConfig = {
      calendar:{
        height: 500,
        editable: false,
        header:{
          left: 'prev,title,next',
          center: '',
          right: ''
        },
        locale: $scope.localeWithoutRegionCode,
        eventLimit: 3,
        //dayNamesShort: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
       // eventClick: $scope.alertOnEventClick,
        eventDrop: $scope.alertOnDrop,
        eventResize: $scope.alertOnResize,
        eventRender: $scope.eventRender,
        dayRender: $scope.dayRender,
        eventLimitText: $scope.evenLimitTextName
        //viewRender: $scope.renderView
      }
    };
    
    $scope.eventSources = [$scope.eventsF];
    $scope.showMonthView = calendarService.getShowMonthView();

	var bvSliderService;

	var sliderRenderCallback = function(){
		bvSliderService = bvSliderInstanceService.instances[$scope.sliderOptionsCards.id];
		var lastStateName = calendarService.getLastState();
		if(lastStateName === "CONF_EVENT")
		{

		}
		else{
			bvSliderService.currentIndex = 0;
		}
		//bvSliderService.currentIndex = 0;
	}

	$scope.sliderOptionsCards = {
			id: 'MEETING_CARDS',
			items : [],
			shownItems: 3,
			slideThrough: 1,
			renderCallback : sliderRenderCallback
	}

	// if($scope.expandedMenu) $scope.sliderOptionsCards.shownItems = 2;

//	$scope.$watch('expandedMenu', function(newValue, oldValue) {
//	// 	if(newValue) {
//	// 		$scope.sliderOptionsCards.shownItems = 2;
//	// 	} else {
//	// 		$scope.sliderOptionsCards.shownItems = 3;
//	// 	}
//	// 	//console.log("Before calling fix scroll from meetings...");
//		var id;
//		if(newValue != oldValue)
//		{
//			clearTimeout(id);
//    		id = setTimeout(function(){
//					bvSliderService.fixScroll();
//			}, 100);
//		}
//	// 	//
//
//	});
//
	$scope.scrollListOptions = {
			columns : [
	    		           	{ title : '' , width : 100, name : 'name' }
			],
			list : calendarService.getAllCalendarEvents()
	    }


	$scope.titleAgenda = localizedMessages.get('AGENDA');

	$scope.tileCheckBox = { title : localizedMessages.get("PUBLISH")  , show : false , active : false };

//	var today = function()
//	{
//		bvSliderService.currentIndex = 6;
//		bvSliderService.fixScroll();
////		angular.element(document.querySelector('[rn-carousel]')).scope().carouselIndex = 3;
////		angular.element(document.querySelector('[rn-carousel]')).scope().prevSlide();
//	}

	var fetchMinutesLandingPage = function() {
		console.log("Minutes Landing Page")
		$state.go('home.minutesLandingPage')
	}
	var manageTemplatesLandingPage = function() 
	{
		console.log("manageTemplates Landing Page")
		$state.go('home.manageTemplates')
	}
    var virtualMeetingConfiguration = function()
	{
		console.log("Virtual Meeting Configuration Landing Page")
		$state.go('home.virtualMeetingConfiguration')
	}
	var newMeeting = function(day)
	{
		if(MobileUtil.isApp() && MobileUtil.isJupOffline())
		{
			return;
    	}
    	console.log("$scope.showNewMeetingDialog",$scope.showNewMeetingDialog);
    
		if(legacyUserService.isNasdaqUI){
		if(day)
			$state.go('home.meetingCreation', { day: day.day })
		else
			$state.go('home.meetingCreation')
		}
    
		if($scope.showNewMeetingDialog)
			{
				$scope.middleBarObject.title = localizedMessages.get('MEETINGS');
				$scope.showNewMeetingDialog = false;
			}
			else
			{
				//homeService.set_current_module_has_unsaved_changes(true, ctrlName);
				$scope.meetingObj	=	modelService.getMeetingCenterObject();

				var d = new Date();

				if(day)
					d = moment(day.day)._d;

				var currentMonth = ((d.getMonth()+1)<10 ? "0"+(d.getMonth()+1):(d.getMonth()+1));
				var currentDate	 = d.getDate() < 10 ? ("0"+d.getDate()) : d.getDate();
				var currentYear  = d.getFullYear();

				var currentDateFormat =  currentMonth + "/" + currentDate  + "/" + currentYear;

				var newStartDateFormat = moment(d).format("ll");
				var newStartDateFormatLocal = StringUtils.momentL(d).format("ll");

				$scope.meetingObj.editFlag			= false;
				$scope.meetingObj.startingDate 		= newStartDateFormat;
				$scope.meetingObj.startingDateLocal	= newStartDateFormatLocal;
				$scope.meetingObj.endingDate		= newStartDateFormat;
				$scope.meetingObj.endingDateLocal	= newStartDateFormatLocal;

				var timeNow = new Date();
				timeNow.setMilliseconds(Math.round(timeNow.getMilliseconds() / 1000) * 1000);
				timeNow.setSeconds(Math.round(timeNow.getSeconds() / 60) * 60);
				timeNow.setMinutes(Math.round(timeNow.getMinutes() / 30) * 30);
				$scope.meetingObj.startingTime          = moment(timeNow).format('hh:mm A');
				$scope.meetingObj.startingTimeLocal     = StringUtils.momentL(timeNow).format('hh:mm A');
				timeNow.setMinutes(timeNow.getMinutes() + 30);
				$scope.meetingObj.endingTime            = moment(timeNow).format('hh:mm A');
				$scope.meetingObj.endingTimeLocal       = StringUtils.momentL(timeNow).format('hh:mm A');

				$scope.showNewMeetingDialog = true;

				var attendeeObj = {
					objectBaseUuid		: context.getModel().userVO.objectBaseUuid,
					name				: context.getModel().userVO.name,
					isPresenter			:false,
					isOwner				:true,
					isOnBehalf			:true,
					response			:'accepted'
				};
				$scope.meetingObj.attendeesList.push(attendeeObj);

				//$scope.middleBarObject.title = "Meeting-Title";
				$scope.svgFill = '#FFF';
				//var rightMenuList = [];
				//$scope.tabs = [{ title:'Details', rightMenuList:rightMenuList}];
				//console.log("MiddleBarObject",$scope.middleBarObject);

				var tabs = [];
				var rightMenuSave =   [
										//{ title:localizedMessages.get('CANCEL'), click: cancelCreateNewMeetingEvent,         svgFill: '#fff', iconUrl: ''},
										//{ title:localizedMessages.get('SAVE'), click: createNewMeetingEvent,         svgFill: '#fff', iconUrl: ''}
										];

				tabs.push({ title:$scope.middleBarObject.title,   content:'', 	rightMenuList:rightMenuSave   });
				/*switch ($rootScope.SELECTED_TAB_FROM_CONF)
				{
					case ""+localizedMessages.get('DAILY')   :
																tabs.push({ title:localizedMessages.get('DAILY'),   content:'cards',    rightMenuList:rightMenuSave,  func: clickCards,   active:true});
																tabs.push({ title:localizedMessages.get('MONTHLY'), content:'monthly',  rightMenuList:rightMenuAll,   func: clickMonthly  });
																tabs.push({ title:localizedMessages.get('YEARLY'),  content:'timeline', rightMenuList:rightMenuAll,   func: clickTimeLine });
																break;
					case ""+localizedMessages.get('MONTHLY') :
																tabs.push({ title:localizedMessages.get('DAILY'),   content:'cards',    rightMenuList:rightMenuAll,  func: clickCards });
																tabs.push({ title:localizedMessages.get('MONTHLY'), content:'monthly',  rightMenuList:rightMenuSave, func: clickMonthly,  active:true});
																tabs.push({ title:localizedMessages.get('YEARLY'),  content:'timeline', rightMenuList:rightMenuAll,  func: clickTimeLine });
																break;
					case ""+localizedMessages.get('YEARLY')  :
																tabs.push({ title:localizedMessages.get('DAILY'),   content:'cards',    rightMenuList:rightMenuAll,  func: clickCards });
																tabs.push({ title:localizedMessages.get('MONTHLY'), content:'monthly',  rightMenuList:rightMenuAll, func: clickMonthly});
																tabs.push({ title:localizedMessages.get('YEARLY'),  content:'timeline', rightMenuList:rightMenuSave,  func: clickTimeLine,  active:true });
																break;
				}*/
				$scope.tabs = tabs;
			}

			$scope.tileCheckBox.show = $scope.showNewMeetingDialog;
			$scope.tileCheckBox.active = false;
	}

	var cancelCreateNewMeetingEvent = function()
	{
		$scope.handleLeftIcon();
	}

	var createNewMeetingEvent = function()
	{
		var meetingController = angular.element(document.querySelector('[bvMeetingEditViewDiv]')).scope();
		meetingController.save();
	}

	$scope.meetingSaveCallback = function(meetingObjectBaseUuid)
	{
		$scope.showNewMeetingDialog = false;
		$scope.tileCheckBox.show = $scope.showNewMeetingDialog;
		$scope.meetingObj.editFlag	= false;
		$scope.initCalendar();
		//$state.go($rootScope.SELECTED_CALENDAR_STATE_NAME);
	}

	$scope.toggleActive = function()
   	{
   		$scope.meetingObj.isActive = !$scope.meetingObj.isActive

   		$scope.tileCheckBox.active = $scope.meetingObj.isActive;
   	}

	var getTabs = function ()
	{
		var newMeetingItem = { title:localizedMessages.get('NEW_MEETING'), click: newMeeting, iconUrl: '', svgFill: '#fff', stateName: 'NewMeeting'};
		var minutesLandingPage = { title:localizedMessages.get('MINUTES'), click: fetchMinutesLandingPage, iconUrl: '', svgFill: '#fff', stateName: 'Minutes'};
		var manageTemplates = { title:localizedMessages.get('AGENDA_TEMPLATES'), click: manageTemplatesLandingPage, iconUrl: '', svgFill: '#fff', stateName: 'manageTemplates'};
		var virtualMeetingConfig = { title:localizedMessages.get('VIRTUAL_MEETING_CONFIGURATION'), click: virtualMeetingConfiguration, iconUrl: '', svgFill: '#fff', stateName: 'virtualMeetingConfiguration'};
		var current = { title:localizedMessages.get('CURRENT'), click: goToCurrentCard, iconUrl: '', svgFill: '#fff', stateName: 'Current'};
		var rightMenuAll =[];
		var tabs = [];
		var canCurrentUserCreateModule = false;
		calendarService.setIsCurrentUserAllowedToCreateModule(canCurrentUserCreateModule);
		var config = "false";

		if(context.getContext().getModel().companyVO.enableMeetingMinutes) {
            rightMenuAll.push(minutesLandingPage);
        }

//		if(context.getModel().isUserPortalAdmin == "true" || context.getModel().isUserPortalAdmin == true || context.getModel().isUserPortalAdmin == "TRUE"){
//			rightMenuAll.push(newMeetingItem);
//			rightMenuAll.push(current);
//			calendarService.setIsCurrentUserAllowedToCreateModule(true);
//		}
//		else{
			commonService.checkModuleCreatorsConfigPresentJSON("MEETINGS").then(function(data){
				if(data && data.data && data.data.name == "isModuleCreatorsPrefAllowed"){
					config = data.data.value;
					if(config == "true" || config == true){
						commonService.getModuleCreatorsForSpecificModuleJSON("MEETINGS").then(function(mod){
							if(mod.data){
								var moduleCreators = angular.fromJson(mod.data);
								if(moduleCreators.users != undefined && moduleCreators.users.length>0){
									for(var i=0;i<moduleCreators.users.length;i++){
										if(context.getModel().userVO.objectBaseUuid == moduleCreators.users[i].objectBaseUuid){
											canCurrentUserCreateModule = true;
											calendarService.setIsCurrentUserAllowedToCreateModule(canCurrentUserCreateModule);
											break;
										}
									}
									if(canCurrentUserCreateModule){
										if (context.getModel().companyVO.enableVirtualMeetings || context.getModel().companyVO.enableTeamsVirtualMeetings) {
											rightMenuAll.push(virtualMeetingConfig);
										}

										if(legacyUserService.isNasdaqUI && context.getModel().companyVO.enableAgendaBuilder) {
											rightMenuAll.push(manageTemplates);
										}
								
										rightMenuAll.push(newMeetingItem);
									}
								}
							}
							//rightMenuAll.push(current);
						});
					}
					else{
						if(legacyUserService.isNasdaqUI && context.getModel().companyVO.enableAgendaBuilder) {
							rightMenuAll.push(manageTemplates);
						}
						rightMenuAll.push(newMeetingItem);
						//rightMenuAll.push(current);
						calendarService.setIsCurrentUserAllowedToCreateModule(true);
					}
				}
			});
//		}

		var tabs = [];
		
	    if(!legacyUserService.isNasdaqUI)
	        tabs.push({ title: ( $rootScope.SELECTED_CALENDAR_STATE_NAME=='home.calendar' ? localizedMessages.get('MONTHLY_VIEW') : localizedMessages.get('MEETING_VIEW')),   content:'', 	rightMenuList:rightMenuAll});
	    else{
            tabs.push({ title: localizedMessages.get('CARD_UPPERCASE'), content:'', rightMenuList:rightMenuAll });
            tabs.push({ title: localizedMessages.get('LIST_UPPERCASE'), content:'', rightMenuList:rightMenuAll });
            tabs.push({ title: localizedMessages.get('MONTH_UPPERCASE'), content:'', rightMenuList:rightMenuAll });
		}

		const calendarTab = calendarService.getMeetingsTabToLocalStorage();
		if(legacyUserService.isNasdaqUI && calendarTab && calendarTab > 0){
			tabs.forEach(tab => tab.active = false)
			tabs[calendarTab].active = true;
		}

        calendarService.tabs = tabs;
        
        const toppanel = toppanelService.getInstance('MEETINGS');
		if(toppanel)
			toppanel.addEvent('tabs', calendarService.tabs);

		return tabs;
	}
	
	$scope.handleLeftIcon = function()
	{
		$scope.tabs = getTabs();
		$scope.showNewMeetingDialog = false;
		$scope.middleBarObject.title = localizedMessages.get('MEETINGS');
		setTimeout(function(){
		    bvSliderService.fixScroll();
		}, 100);
		$scope.tileCheckBox.show = $scope.showNewMeetingDialog;
	}

	$scope.clickEvent = function(dt, eventObj,eventsForDay)
	{
		var events = [];
		if(eventsForDay!=null && eventsForDay!=undefined)
		{
			for (var i = 0; i < eventsForDay.length; i++)
			{
			    if(eventsForDay[i].data)
					events.push(eventsForDay[i].data);
			}
		}
		else
		{
			events.push(eventObj.data);
		}
		var SELECTED_EVENT = null;
		var dateValue = StringUtils.momentL(dt).format('ll');
		if(eventObj!=null && eventObj!=undefined && eventObj.data!=undefined)
		{
			SELECTED_EVENT = eventObj.data.objectBaseUuid;
		}
		var EVENTS_LIST = { "DATE":dateValue, "EVENTS":events, "SELECTED_EVENT":SELECTED_EVENT};
		$rootScope.EVENTS_LIST = EVENTS_LIST;
		$state.go('home.meetingDetails');
    }

 	$scope.titleDateFormat = 'MMM D, YYYY';
    $scope.clickEventTimeline = function(eventObj)
    {
        var uniq_date = moment(parseInt(eventObj.startDateGMTMillis)).format($scope.titleDateFormat);
        var EVENTS_LIST = {
                                DATE:uniq_date,
                                EVENTS: $scope.events.eventsMap.get(uniq_date),
                                SELECTED_EVENT : eventObj.objectBaseUuid
                            };
        $rootScope.EVENTS_LIST = EVENTS_LIST;
        $rootScope.SELECTED_TAB_FROM_CONF = ""+localizedMessages.get('YEARLY');
        $state.go('home.meetingDetails');
    }

	$scope.getFormatedTime = function(startDateGMTMillis,endDateGMTMillis)
  {
		var dateHelper = new DateHelper(context.getModel().userVO);

		return dateHelper.getFormattedRangeTime(startDateGMTMillis, endDateGMTMillis, moment.tz.guess(true));
	}

	$scope.clickDateInMeetingCard = function(uniq_date)
    {
		var EVENTS_LIST = {
								DATE:uniq_date,
								EVENTS: $scope.events.eventsMap.get(uniq_date),
								SELECTED_EVENT :$scope.events.eventsMap.get(uniq_date)[0].objectBaseUuid
							};
		$rootScope.EVENTS_LIST = EVENTS_LIST;
        $rootScope.SELECTED_TAB_FROM_CONF = ""+localizedMessages.get('DAILY');
        $state.go('home.meetingDetails');
	}

	$scope.clickEventInMeetingCard = function(uniq_date,selectedUuid)
    {
		if(MobileUtil.isAndroid() || MobileUtil.isIPhone()){
			bvDialogMobile.mobileMeetingDetails($scope.events.eventsMap.get(uniq_date),selectedUuid);
		}
		else{
			var EVENTS_LIST = {
					DATE:uniq_date,
					EVENTS: $scope.events.eventsMap.get(uniq_date),
					SELECTED_EVENT :selectedUuid
			};
			$rootScope.EVENTS_LIST = EVENTS_LIST;
	        $rootScope.SELECTED_TAB_FROM_CONF = ""+localizedMessages.get('DAILY');
	        $state.go('home.meetingDetails');
		}
	}

	$scope.getLocalizedDate = function(date) {
		//return date != undefined ? localizedMessages.getLocaleDate(date, 'LL') : "";
		return localizedMessages.getLocaleDate(date);
	}

	var goToCurrentCard = function()
	{
		var magCurrent = calendarService.getLastState();
		var totalDates = $scope.events.uniq_eventDates.length - 1 ;

		if(magCurrent === "CONF_EVENT")
		{
			calendarService.setLastState("");
		}
		else
		{
		    if(bvSliderService && "currentIndex" in bvSliderService){
                for(var i=0; i<= totalDates;i++)
                {
                    //console.log("$scope.events.uniq_eventDates[i]",$scope.events.uniq_eventDates[i], " EVENT_DAY:",$scope.events.EVENT_DAY_VALUE)
                    if($scope.events.uniq_eventDates[i] == $scope.events.EVENT_DAY_VALUE)
                    {
                        if(MobileUtil.isAndroid() || MobileUtil.isIPhone()){
                            bvSliderService.currentIndex = i;
                        }
                        else{
                            if(i >= (totalDates - $scope.sliderOptionsCards.shownItems))
                            {
                                bvSliderService.currentIndex = i - 1;
                                //$scope.sliderOptionsCards.shownItems = 2;
                            }
                            else
                            {
                                bvSliderService.currentIndex = ((i==1) ? i : (i-1));
                                //bvSliderService.currentIndex = i;
                                //bvSliderService.currentIndex = i + 1;
                            }
                        }
                        break;
                    }
                }
			}
		}
		$scope.sliderOptionsCards.items = $scope.events.uniq_eventDates;
		$scope.noMeetings = $scope.sliderOptionsCards.items.length == 0 ? true : false;
		setTimeout(function(){
		    if(bvSliderService && bvSliderService.fixScroll){
                bvSliderService.fixScroll();
                bvSliderService.fixButtons();
			}
		},0);
		$scope.msg_no_meetings = localizedMessages.get('MSG_NO_MEETINGS');
		$scope.current = localizedMessages.get("CURRENT");
		$scope.today = localizedMessages.get("TODAY");
		$scope.isMobileClient = MobileUtil.isMobileClient();
		//$timeout(function(){bvSliderService.fixScroll()}, 100);
	}

	$scope.showCurrentMeeting = function(){
		goToCurrentCard();
	}

	$scope.showMinutesLandingPage = function() {
		fetchMinutesLandingPage();
	}

	$scope.initCalendar = function()
	{
		$scope.middleBarObject.title = localizedMessages.get('MEETINGS');
		$scope.middleBarObject.showBack = false;
		$scope.showNewMeetingDialog = false;
		var currentState = $state.$current.name;
		$rootScope.SELECTED_CALENDAR_STATE_NAME = currentState;
		$rootScope.SELECTED_CALENDAR_MODULE_TITLE = StringUtils.capitaliseFirstLetter(currentState.split("home.")[1]);
		//$scope.middleBarObject.title = $rootScope.SELECTED_CALENDAR_MODULE_TITLE;
		$scope.events = calendarService.getAllCalendarEvents(true, function(){
			goToCurrentCard();
			updateRunningStateOfEvents();
		});

		//$scope.events = calendarService.getAllCalendarEvents();
		$scope.uintToRgb = StringUtils.uintToRgb;
		$scope.teamSpaceName = context.getModel().portalVO.name;
		//console.log("CURRENT STATE:",$scope.currentState)
		$scope.tabs = getTabs();
		$scope.$parent.currentState.value = currentState;
		//console.log("FIXSCROLL--GO_TO_INIT_CALENDAR");
		//bvSliderService.fixScroll();
		//$scope.goToCurrent();
		homeService.set_current_module_has_unsaved_changes(false, ctrlName);
	}

	// to show orange bar
	$scope.runningConfs = presentationService.getRunningConfs();
    $scope.$on('runningConfs:updated', function(event,runningConfs) {
          $scope.runningConfs = runningConfs;
          updateRunningStateOfEvents();
          console.log("runningConfs:updated" , runningConfs);
    });

    var updateRunningStateOfEvents = function()
    {
    	for(var i in $scope.events.uniq_eventDates)
    	{
    		var uniq_date = $scope.events.uniq_eventDates[i];
    		for(var e in $scope.events.eventsMap.get(uniq_date))
	    	{
   				$scope.events.eventsMap.get(uniq_date)[e].isRunning = false;
   			}
   		}

    	if($scope.runningConfs.length > 0 && Object.keys($scope.events).length != 0)
    	{
    		// loops only for running confs found - which will be less number than actual events
	    	for(var i in $scope.runningConfs)
	    	{
	    		var uniq_date = moment(parseInt($scope.runningConfs[i].startDateGMTMillis)).format($scope.titleDateFormat);
	    		for(var e in $scope.events.eventsMap.get(uniq_date))
	    		{
	    			if($scope.runningConfs[i].objectBaseUuid == $scope.events.eventsMap.get(uniq_date)[e].objectBaseUuid)
	    			{
	    				$scope.events.eventsMap.get(uniq_date)[e].isRunning = true;
	    			}
	    		}
	    	}
	    }
    }

    $scope.getTitleDate = function(uniq_date){
    	//return uniq_date != undefined ? localizedMessages.getLocaleDate(uniq_date, 'LL') : "";
			var dateHelper = new DateHelper(context.getModel().userVO);
			return dateHelper.getFormattedLongDate(uniq_date);
    	//return uniq_date != undefined ? moment($filter('date')(date, 'medium')).format('LL') : "";
    }

	$scope.initCalendar();
	$scope.colors = ["#fc0003", "#f70008", "#f2000d", "#ed0012", "#e80017", "#e3001c", "#de0021", "#d90026", "#d4002b", "#cf0030", "#c90036", "#c4003b", "#bf0040", "#ba0045", "#b5004a", "#b0004f", "#ab0054", "#a60059", "#a1005e", "#9c0063", "#960069", "#91006e", "#8c0073", "#870078", "#82007d", "#7d0082", "#780087", "#73008c", "#6e0091", "#690096", "#63009c", "#5e00a1", "#5900a6", "#5400ab", "#4f00b0", "#4a00b5", "#4500ba", "#4000bf", "#3b00c4", "#3600c9", "#3000cf", "#2b00d4", "#2600d9", "#2100de", "#1c00e3", "#1700e8", "#1200ed", "#0d00f2", "#0800f7", "#0300fc"];
	$scope.carouselIndex = 0;

	$scope.topMeetingsPanel = {
        templateUrl: 'modules/meetings/toppanel/toppanel.template.html',
        name: 'MEETINGS',
        events : [
            { name: 'current',      value : localizedMessages.get('CURRENT')},
            { name: 'today',        value : localizedMessages.get('TODAY')},
//            { name: 'status',       value : localizedMessages.get('STATUS_UPPERCASE')},
//            { name: 'nameClk',      value : function(){ $scope.selectMenuItem({ value: "name" }) }},
//            { name: 'dueClk',       value : function(){ $scope.selectMenuItem({ value: "dueDateLong" }) }},
//            { name: 'statusClk',    value : function(){ $scope.selectMenuItem({ value: "progress" })}},
			{ name: 'tabs',         value : calendarService.tabs},
			{ name: 'selectTab', 	value : calendarService.setMeetingsTabToLocalStorage }
        ]
    }

    if($stateParams.objectBaseUuid){
      calendarService.getAllCalendarEvents(true, listEvents => {
        if(listEvents && listEvents.listObjects){
          const event = listEvents.listObjects.find(event => event.objectBaseUuid == $stateParams.objectBaseUuid)
          if(event && event.startDate){
            const date = StringUtils.momentL(event.startDate).format('ll')
            calendarService.tabs.forEach(tab => tab.active = false);
            $scope.reactInterface.clickedEventFromMeetX = {
              date,
              eventBaseUuid: $stateParams.objectBaseUuid,
              clickedDay: date
            }
            calendarService.tabs[1].active = true;
          }
        }
      });
    }

}

MeetingNotificationController.$inject = ['$scope', '$rootScope', '$state', '$locale', '$stateParams', 'calendarService', 'commonService', 'presentationService', 'localizedMessages', 'ipadService', '$modal', '$templateCache', 'context','modelService','bvDialogService','permissionsService', 'bvSliderInstanceService', '$timeout', 'bvDialog', 'bvDialogMobile', 'homeService', 'legacyUserService', 'reactInterface','$compile','eventsdialogService', 'toppanelService', '$filter'];
function MeetingNotificationController($scope, $rootScope, $state, $locale, $stateParams, calendarService, commonService, presentationService, localizedMessages, ipadService, $modal, $templateCache, context, modelService, bvDialogService, permissionsService, bvSliderInstanceService, $timeout, bvDialog, bvDialogMobile, homeService, legacyUserService, reactInterface,$compile,eventsdialogService, toppanelService, $filter){
	var activeMeeting = $scope.participants = $scope.bvModalControllerInputs.activeMeeting
	var eventType = $scope.bvModalControllerInputs.eventName;
	var dateHelper = new DateHelper(context.getModel().userVO);

	calendarService.getPortalData().then(function (data) {
		if(data){
			$scope.notifyData.isICSFileIncluded = data.userVO.isICSFileIncluded
		}
	});
	$scope.notifyData = {
		calendarEventBaseUuid : $scope.bvModalControllerInputs.activeMeeting.objectBaseUuid,
		attendees : $scope.bvModalControllerInputs.activeMeeting.guests,
		subject : eventType === "deleteAlert" ? "Canceled Meeting: " +activeMeeting.name+" - "+dateHelper.getFormattedLongDateWithDay(activeMeeting.startDateVO.gmtMilliSeconds)+" - "+ dateHelper.getFormattedTimeWithTz(activeMeeting.endDateVO.gmtMilliSeconds, activeMeeting.eventTimezone ? activeMeeting.eventTimezone : moment.tz.guess()) : "Meeting Materials are now available for "+activeMeeting.name+" - "+dateHelper.getFormattedLongDateWithDay(activeMeeting.startDateVO.gmtMilliSeconds)+" - "+dateHelper.getFormattedTimeWithTz(activeMeeting.endDateVO.gmtMilliSeconds, activeMeeting.eventTimezone ? activeMeeting.eventTimezone : moment.tz.guess()) ,
		announcementText : eventType === "deleteAlert" ? "This meeting has been canceled in your " +context.getModel().companyVO.name+" Nasdaq Boardvantage account" : "Meeting documents are ready for you to review in your "+context.getModel().companyVO.name+" Nasdaq Boardvantage account" ,
		isICSFileIncluded : false
	};
	$scope.participants = $scope.bvModalControllerInputs.activeMeeting.guests;
	var userIds = $scope.notifyData.attendees.map(user => user.objectBaseUuid);
	$scope.ui = { label : {
			subject : localizedMessages.get("SUBJECT"),
			message : localizedMessages.get("MESSAGE"),
			send : localizedMessages.get("SEND"),
			to : localizedMessages.get("TO"),
			popupTitle : $scope.bvModalControllerInputs.popupTitle,
		}
	};
	$scope.editorOptions = {
		   //language: 'en',
           resize_enabled : false,
           enterMode : CKEDITOR.ENTER_BR,
           height:"150px",
           toolbarCanCollapse:false,
           toolbarStartupExpanded:false,
           removePlugins: 'elementspath',
           toolbar : [
			               { name: 'basicstyles', 	items: [ 'Bold', 'Italic','Underline' ] },
			               { name: 'color', 		items: [ 'TextColor' ] },
			               { name: 'paragraph',     items: [ 'NumberedList', 'BulletedList'] }
			           ],
		   extraPlugins : 'colorbutton,colordialog,font,link,basicstyles,toolbar,justify,wordcount',
		   wordcount: {
               showParagraphs: false,
               showWordCount: false,
               showCharCount: true,
               countSpacesAsChars:true,
               countHTML:false,
               maxWordCount: -1,
               maxCharCount: 10000
           },
    };
	var buttons = $scope.bvModalControllerInputs.buttons;
	$scope.cancelEdit = function(){
		$scope.bvModalControllerInputs.bvModal.close();
    	$scope.subject = ""
	    $scope.announcementText = ""
	}
	var sendMeetingNotification = function(calendarEventBaseUuid, usersObjectBaseUuidList, subject, announcementText){
		var isICSFileIncluded = $scope.notifyData.isICSFileIncluded ? "true" : "false";
		if($scope.notifyData.subject === ""){
			bvDialog.confirm( localizedMessages.get('DO_YOU_WANT_THE_SEND_THE_MESSAGE_WITHOUT_A_SUBJECT'), function(){
			calendarService.sendCustomizedEventAlert(calendarEventBaseUuid, usersObjectBaseUuidList, subject, announcementText, isICSFileIncluded);
		    $scope.bvModalControllerInputs.bvModal.close();
		});
		}else{
			if(eventType === "deleteAlert")
			calendarService.deleteAndSendCustomizedAlertForMeetingCenterCalendarEvent(calendarEventBaseUuid, usersObjectBaseUuidList, subject, announcementText, isICSFileIncluded).then(function (data) {
				$rootScope.$broadcast('deleteMeetingNotificationSent');
          });
			else
				calendarService.sendCustomizedEventAlert(calendarEventBaseUuid, usersObjectBaseUuidList, subject, announcementText, isICSFileIncluded);
			 
		    $scope.bvModalControllerInputs.bvModal.close();		
		}
	}
	
    if(buttons) buttons[0].name = localizedMessages.get('CANCEL');
    if(buttons) buttons[1].name = localizedMessages.get('SEND');

    if(buttons) buttons[0].action = function(){
		bvDialog.confirm( localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED'), $scope.cancelEdit );
	}
	if(buttons) buttons[1].action = function(){
		sendMeetingNotification($scope.notifyData.calendarEventBaseUuid, angular.toJson(userIds), encodeURIComponent($scope.notifyData.subject), encodeURIComponent($scope.notifyData.announcementText));
	}
    $scope.bvModalControllerInputs.closeButton = function() {
    	bvDialog.confirm( localizedMessages.get('MSG_CANCEL_EDIT_CHANGES_WILL_NOT_SAVED'), $scope.cancelEdit );
	};
	$scope.$watch('notifyData.attendees', function() {
	    setTimeout(function(){
	    	userIds = $scope.notifyData.attendees.map(user => user.objectBaseUuid);
			if(userIds.length === 0){
				document.querySelector('.bv-input-btn-1').classList.add("send-button-disabled");
			}else{
				document.querySelector('.bv-input-btn-1').classList.remove("send-button-disabled");
			}
		}, 1000);
    });

	$scope.$watch('notifyData.announcementText', function() {
	    console.log('subject' +$scope.notifyData.announcementText);
    });
}

angular
	.module('calendar', ['calendar.service', 'conference.service','localization.service', 'bvPermissions','conference.module', 'ipad.service','model.service','ui.calendar', 'ui.bootstrap', 'ui.select'])
	.controller('CalendarController', CalendarController)
	.controller('MeetingNotificationController', MeetingNotificationController)
	.filter('cutCardText', function(){
		return function(value,wordwise,tail){
			if (!value) return '';

			var max;
			var iOS = ( navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false );
			if(iOS)
				max = 15;
			else
				max = 25;

            max = parseInt(max, 10);
            if (!max) return value;
            if (value.length <= max) return value;

            value = value.substr(0, max);
            if (wordwise) {
                var lastspace = value.lastIndexOf(' ');
                if (lastspace != -1) {
                    value = value.substr(0, lastspace);
                }
            }

            return value + (tail || ' ...');
		};
	});
