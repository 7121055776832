function DateHelper(user) {
  var timeFormat = user.timeFormat;
  var dateFormat = user.dateFormat;

  this.getFormattedTime = function(startDateTime, timeZone) {
    var f = new DateTimeFormatUtility().setFormat(dateFormat, timeFormat).setTimezone(timeZone);

    return f.setDateTime(startDateTime).formattedTime();
  }

  this.getFormattedTimeWithTz = function(startDateTime, timeZone) {
    var f = new DateTimeFormatUtility().setFormat(dateFormat, timeFormat);
    
    var time = f.setDateTime(startDateTime).formattedTime();
  
    var formattedTz = moment(startDateTime).tz(timeZone).format('zz');

    return time + ' ' + formattedTz;
  }

  this.getFormattedTimeUTC = function(startDateTime) {
    var f = new DateTimeFormatUtility().setFormat(dateFormat, timeFormat);
  
    return f.setUtcDateTime(startDateTime).formattedTime();
  }
  
  this.getFormattedRangeTime = function(startTime, endTime, timeZone) {  
    var f = new DateTimeFormatUtility().setFormat(dateFormat, timeFormat).setTimezone(timeZone);
  
    var formattedStartTime = f.setDateTime(parseInt(startTime)).formattedTime();
    var formattedEndTime = f.setDateTime(parseInt(endTime)).formattedTime();
    
    return formattedStartTime+" - "+formattedEndTime;
  };

  this.getFormattedLongDate = function(dateTime) {
    var formatter = new DateTimeFormatUtility().setDateTime(dateTime).setFormat(dateFormat, timeFormat);
  
    return formatter.formattedLongDate();
  }

  this.getFormattedLongDateWithDay = function(dateTime) {
    var formatter = new DateTimeFormatUtility().setDateTime(dateTime).setFormat(dateFormat, timeFormat);
    var longDate = formatter.formattedLongDate();
    var time = formatter.formattedTime();

    return formatter.dayLong() + ", " + longDate + " " + time;
  }

  this.getForrmattedAbbrDate = function(dateTime) {
    var formatter = new DateTimeFormatUtility().setDateTime(dateTime).setFormat(dateFormat, timeFormat);
  
    return formatter.formattedAbbrDate();
  }

  this.getForrmattedShortDate = function(dateTime) {
    var formatter = new DateTimeFormatUtility().setDateTime(dateTime).setFormat(dateFormat, timeFormat);
  
    return formatter.formattedShortDate();
  }

  this.getDateFormats = function() {
    var formatter = new DateTimeFormatUtility().setFormat(dateFormat, timeFormat);

    return formatter.dateFormats();
  }
}