function configSignaturesController($stateProvider, $locationProvider){
	
}

function SignaturesController($scope, $state, $stateParams, signaturesService, localizedMessages, repositoryService, imageService, context, contextMenuModel, bvDialog, SortUtility){
	//$scope.title = StringUtils.capitaliseFirstLetter($state.$current.name.split("home.")[1]);
//	$scope.rightMenuEnable = true;
	$scope.repositoryList = [];
	$scope.commitedList = [];
	$scope.ownSignList = [];
	$scope.middleBarObject.title =  localizedMessages.get('SIGNATURES');
	$scope.exceptionOccurred = false;
	$scope.exceptionMessage = "";
	$scope.$parent.currentState.value = $state.$current.name;
	$scope.svgFill = "#000";
	$scope.middleBarObject.showBack = false;
	$scope.MobileUtil = MobileUtil;
	$scope.showProgress = false;
	$scope.noSignaturesLabel = localizedMessages.get('NO_SIGNATURES');
	
	if(!context.getModel().isN3aUser && !context.getModel().isOnPrem){
		$scope.tabs = [
	       	{title : localizedMessages.get('SIGNATURES'), content : 'all', active: true}
	      ];
	}else{
		$scope.tabs = [
	       	{title : localizedMessages.get('OPEN'), content : 'all', active: true},
	       	{title : localizedMessages.get("CLOSED"), content : 'closed'},
	       	{title : localizedMessages.get("OWN"), content : 'own'}
	      ];
	}

	$scope.showProgress = true;
	
	$scope.ui = {
			label : {
				modifiedBy : localizedMessages.get('MODIFIED_BY'),
				by : localizedMessages.get('BY'),
				on : localizedMessages.get('ON'),
				dateModified : localizedMessages.get('DATE_MODIFIED'),
				fileSize : localizedMessages.get('FILE_SIZE'),
				defaultExpiryLabel : localizedMessages.get('DEFAULT_EXPIRY_LABLE'),
				days : localizedMessages.get('DAYS')
			}
	}
	
	$scope.middleBarObject.showBack = false;
	
	$scope.topRepositoryPanel = {
        templateUrl: 'modules/signatures/toppanel/toppanel.template.html',
        events : [
            { name: 'name',         value : localizedMessages.get('NAME_UPPERCASE')},
            { name: 'status',       value : localizedMessages.get('STATUS_UPPERCASE')},
            { name: 'nameClk',      value : function(){ $scope.selectMenuItem({ value: "name" }) }},
            { name: 'statusClk',    value : function(){ $scope.selectMenuItem({ value: "progress" })}},
            { name: 'tabs',         value : $scope.tabs}
        ]
    }
	$scope.getDocumentsForSignatureTabs = function (tab) 
    {
		switch(tab){
	        case 'open':
	        	$scope.openSignList = [];
	        	signaturesService.getDocumentsForSignatureTabs(tab).then(function(data)
    	        {
    				if(data)
    				{
    					$scope.openSignList = StringUtils.filterBOForSelectedObjectBaseUuid(data.data, context.getModel().portalVO.objectBaseUuid);
    				}
    	        });
	        break;
	        case 'closed':
	        	$scope.closedList = [];
	        	signaturesService.getDocumentsForSignatureTabs(tab).then(function(data)
    	        {
    				if(data)
    				{
    					$scope.closedList = StringUtils.filterBOForSelectedObjectBaseUuid(data.data, context.getModel().portalVO.objectBaseUuid);
    				}
    	        });
	        break;
	        case 'own':
	        	$scope.ownSignList = [];
	        	signaturesService.getDocumentsForSignatureTabs(tab).then(function(data)
    	        {
    				if(data)
    				{
    					$scope.ownSignList = StringUtils.filterBOForSelectedObjectBaseUuid(data.data, context.getModel().portalVO.objectBaseUuid);
    				}
    	        });
	        break;
		}	
    };
	

	$scope.getDocumentsForSignature = function () 
    {
		signaturesService.getDocumentsForSignature().then(function(data)
        {
			$scope.fullViewDocumentList = {"padding-left":"0"};
			
			if(data.data.message != undefined)
			{
				$scope.exceptionOccurred = true;				
				$scope.exceptionMessage = data.data.message;
			}
			else
			{
				$scope.repositoryList = StringUtils.filterBOForSelectedObjectBaseUuid(data.data, context.getModel().portalVO.objectBaseUuid);
			}
			$scope.noSignatures = $scope.repositoryList.length == 0 ? true : false;
			$scope.msg_no_signatures = localizedMessages.get('MSG_NO_SIGNATURES');
        });
    };
    
    $scope.getCommitedSignaturesDocs = function()
    {
    	signaturesService.getSignCommitedDocuments().then(function(data)
        {
			$scope.fullViewDocumentList = {"padding-left":"0"};
			
			if(data.data.message != undefined)
			{
				$scope.exceptionOccurred = true;				
				$scope.exceptionMessage = data.data.message;
			}
			else
			{
				$scope.commitedList = StringUtils.filterBOForSelectedObjectBaseUuid(data.data, context.getModel().portalVO.objectBaseUuid);
			}
			$scope.noSignatures = $scope.commitedList.length == 0 ? true : false;
			$scope.msg_no_signatures = localizedMessages.get('MSG_NO_SIGNATURES');
        });
    };

    $scope.getOwnedSignaturesDocs = function () 
    {
		signaturesService.getOwnedSignatureDocuments().then(function(data)
        {
			$scope.fullViewDocumentList = {"padding-left":"0"};
			
			if(data.data.message != undefined)
			{
				$scope.exceptionOccurred = true;				
				$scope.exceptionMessage = data.data.message;
			}
			else
			{
				$scope.ownSignList = StringUtils.filterBOForSelectedObjectBaseUuid(data.data, context.getModel().portalVO.objectBaseUuid);
			}
			$scope.noSignatures = $scope.ownSignList.length == 0 ? true : false;
			$scope.msg_no_signatures = localizedMessages.get('MSG_NO_SIGNATURES');
        });
    };

	 $scope.reverseSort = false;
	  $scope.selectMenuItem = function(ob)
		{		
			if(ob.value == "name")
			{
				if($scope.tabs[0].active)
					$scope.repositoryList = SortUtility.sortByField($scope.repositoryList, ['name', 'progressBar'], $scope.reverseSort);
				if($scope.tabs[1].active)
					$scope.closedList = SortUtility.sortByField($scope.closedList, ['name', 'progressBar'], $scope.reverseSort);
				if($scope.tabs[2].active)
					$scope.ownSignList = SortUtility.sortByField($scope.ownSignList, ['name', 'progressBar'], $scope.reverseSort);
			}
			else if(ob.value == "progress")
			{
				if($scope.tabs[0].active)
					$scope.repositoryList = SortUtility.sortByField($scope.repositoryList, ['progressBar', 'name'], $scope.reverseSort);
				if($scope.tabs[1].active)
					$scope.closedList = SortUtility.sortByField($scope.closedList, ['progressBar', 'name'], $scope.reverseSort);
				if($scope.tabs[2].active)
					$scope.ownSignList = SortUtility.sortByField($scope.ownSignList, ['progressBar', 'name'], $scope.reverseSort);
			}
			$scope.reverseSort = !$scope.reverseSort;
		}
	
	
	
	
    $scope.handleRepositoryForItemSelected = function (event, document) 
    {
    	repositoryService.openPdfViewer(document);
    };

    $scope.getDocumentThumbnail = function(document) 
	{
		return imageService.getThumbnailForObject(document);
	};

	$scope.getDocumentImage = function(document) 
	{
		return imageService.getImageForObject(document);
	};	

	$scope.showSignatureStatus = function(document)
	{
		bvDialog.showSignatureStatus(document);
	};
	
	
	
    /**
     * Right Click Menu Handler for signature Lisitng
     *
    */
    $scope.currentInstance = "SIGNATURES";

	$scope.showSignaturesMenu = function(event, approval)
    {
		$scope.showSignaturesRightClick = false;
		if (event)
		{
			event.preventDefault();
      		event.stopPropagation();
      		$scope.handleRightClick(event.pageX, event.pageY, approval, event);
    	}
    }

    $scope.handleRightClick = function (xPos, yPos, approval, event)
    {
    	contextMenuModel.parentObject = $scope.currentInstance;
    	$scope.rightClickDispatcher(xPos, yPos, approval, event)
    }

	$scope.showSignaturesRightClick = false;

	$scope.rightClickDispatcher = function (xPos, yPos, selectedContextItem, event)
	{

		if(selectedContextItem === undefined || selectedContextItem == null)
			return;
		$scope.selectedObjectFromContextMenu = "";
		contextMenuModel.xPos = xPos;
		contextMenuModel.yPos = yPos;
		contextMenuModel.event = event;
		contextMenuModel.currentInstance = $scope.currentInstance;
		contextMenuModel.selectedObject = selectedContextItem;//TODO
		contextMenuModel.contextMenuCheckOptions = {};
		contextMenuModel.multiple = false;
		$scope.showSignaturesRightClick = true;
	};

	$scope.$watch('showSignaturesRightClick', function(oldValue, newValue){
		console.log(oldValue, newValue);
	}, true);

    $scope.$on('HOME_CONTROLLER_CLICK', function(event){
    	if($scope.showSignaturesRightClick)
        	$scope.showSignaturesRightClick = false;
    });

 	$scope.menuCallback = function(type, param){
    	switch (type)
    	{
            case 'MENU_STYLE':
            	$scope.menuStyle = {'top': param[0].y + "px",'left': param[0].x + "px"};
                break;
            case 'MENU_ARROW_STYLE':
                $scope.menuLeftArrowStyle = {'top': param.y + "px", 'left': param.x + "px"};
                break;
            case 'OPEN':
            	repositoryService.openPdfViewer(contextMenuModel.selectedObject);
            	break;
            case 'STATUS':
            	bvDialog.showSignatureStatus(contextMenuModel.selectedObject);
            	break;
			default:
				break;
		}
    }
}

function SignatureStatusController($scope, context, localizedMessages, legacyUserService, SortUtility)
{
	var bvModal = $scope.bvModalControllerInputs.bvModal;
	var selectedDocument = $scope.bvModalControllerInputs.businessObject;
	var signCommitedList = selectedDocument.signCommitUserMap;
	var signPendingList = selectedDocument.signPendingUserMap;
	var signCommitedListSet = new Set();
	$scope.signCommitedListCount = signCommitedListSet.add(signCommitedList).size;
	$scope.totalUsers = selectedDocument.totalUsers;
	$scope.outOfMsg = localizedMessages.get('OUT_OF');
	$scope.sigCompletedMsg = localizedMessages.get('SIGNATURES_COMPLETED');
	
	$scope.calculateSignCommitedList = function(document){
		var closeCount =0;
		for(var doc in document.signCommitUserMap){
			closeCount++;
		}
		var signatureCommitedList = Math.floor(closeCount);
		return signatureCommitedList;
	};
	$scope.signClosedListCount = $scope.calculateSignCommitedList(selectedDocument);
	
	
	$scope.fileName = selectedDocument.name;
	$scope.popupTitle = !legacyUserService.isNasdaqUI ? $scope.fileName : localizedMessages.get('SIGNATURE_TRACKER');
	$scope.UI_USER = localizedMessages.get('USER');
	$scope.UI_STATUS = localizedMessages.get('STATUS');
	$scope.UI_DATE = localizedMessages.get('DATE');
	$scope.UI_PENDING = localizedMessages.get('PENDING');
	$scope.UI_COMMITED = localizedMessages.get('COMMITED');
	$scope.UI_PROGRESS = localizedMessages.get('PROGRESS');
	$scope.UI_SIGNEE = localizedMessages.get('SIGNEE');
	$scope.UI_SIGNED = localizedMessages.get('SIGNED_UPPERCASE');
	
	$scope.signStatusList = [];
	$scope.progressBar = selectedDocument.progressBar;

	var buildSignStatusList = function()
	{
		for(var userId in signPendingList)
		{
			var signObj = new Object;
			signObj.user = signPendingList[userId];
			signObj.status = $scope.UI_PENDING;
			signObj.commitedOn = "";

			$scope.signStatusList.push(signObj);
		}

		for(var userId in signCommitedList)
		{
			var signObj = new Object;
			var userDetails = signCommitedList[userId];
			var commitedOn = userDetails.substring(userDetails.indexOf("##") + 2);

			signObj.user = userDetails.substring(0, userDetails.indexOf("##"));
			signObj.commitedOn = moment(commitedOn).format("lll");
			signObj.status = $scope.UI_COMMITED;

			$scope.signStatusList.push(signObj);
		}
	}
	
	buildSignStatusList();
	
	
	
	  $scope.reverseSort = false;
	  $scope.selectMenuItem = function(menuItem)
		{		
			if(menuItem == "user")
			{
				$scope.signStatusList = SortUtility.sortByField($scope.signStatusList, ['user', 'status'], $scope.reverseSort);
			}
			else if(menuItem == "status")
			{
				$scope.signStatusList = SortUtility.sortByField($scope.signStatusList, ['status', 'user'], $scope.reverseSort);
			}
			$scope.reverseSort = !$scope.reverseSort;
		}
	
	
	
}


SignatureMenuController.$inject = ['$scope', 'signatureContextMenu','contextMenuModel', 'bvDialog', 'localizedMessages', 'homeService'];

function SignatureMenuController($scope, signatureContextMenu, contextMenuModel, bvDialog, localizedMessages, homeService)
{
	var showContextMenu = function()
	{
		$scope.contextMenuOption = signatureContextMenu.getContextMenu(contextMenuModel.multiple, contextMenuModel.selectedObject, contextMenuModel.parentObject, contextMenuModel.contextMenuCheckOptions);
		console.log($scope.contextMenuOption);
		var point = signatureContextMenu.getPosition(contextMenuModel.xPos, contextMenuModel.yPos, contextMenuModel.selectedObject, $scope.contextMenuOption.length, 31, 210);

    	var event = contextMenuModel.event;

    	!homeService.homeContext.expandedMenu ? point.x = point.x - 300 :  point.x;
    	$scope.currentInstance = contextMenuModel.currentInstance;

        $scope.menuParentCallback("MENU_STYLE",[point]);
      	var arrow_point = signatureContextMenu.getArrowPosition(contextMenuModel.xPos, contextMenuModel.yPos,$scope.contextMenuOption.length, 31, 210);
      	!homeService.homeContext.expandedMenu ? arrow_point.x = arrow_point.x - 300 :  arrow_point.x;
        $scope.menuParentCallback("MENU_ARROW_STYLE",arrow_point) ;
	}

 	$scope.openContextMenu = function(type)
    {
    	switch(type)
    	{
    		case 'open':  $scope.menuParentCallback("OPEN",[]);break;
    		case 'status': $scope.menuParentCallback("STATUS",[]);break;
    	}
    }
	showContextMenu(contextMenuModel.xPos, contextMenuModel.yPos, contextMenuModel.selectedContextItem, contextMenuModel.event);
}

SignatureContextMenu.$inject = ['localizedMessages', 'contextMenuSetting', 'context', 'homeModel','homeContext'];

function SignatureContextMenu(localizedMessages, contextMenuSetting, context, homeModel, homeContext)
{
	var contextMenuOption = {};
	var signatureContextMenu = [];

	contextMenuOption['open'] 	= { title:localizedMessages.get('OPEN'), iconUrl: '', svgFill: '#fff', type: 'open'};
	contextMenuOption['status'] 	= { title:localizedMessages.get('STATUS'), iconUrl: '', svgFill: '#fff', type: 'status'};

	this.getContextMenu = function(isMultipleSelect, businessObject, parentContainer, contextMenuCheckOptions)
	{
		signatureContextMenu = [];
		signatureContextMenu.push(contextMenuOption['open']);
		signatureContextMenu.push(contextMenuOption['status']);
		return signatureContextMenu;
	}

	this.getPosition = function(xPos, yPos, selectedContextItem, length, factor, width)
	{
		return contextMenuSetting.getPosition(xPos, yPos, selectedContextItem, length, factor, width)
	}

	this.getArrowPosition = function(xPos, yPos, length, factor, width)
	{
		return contextMenuSetting.getArrowPosition(xPos, yPos, length, factor, width)
	}
};


function signatureMenu(){
    return{
        restrict: 'A',
        scope:{
        	menuStyle: '=',
        	menuParentCallback:'='
        },
        templateUrl: 'directives/contextmenu/ContextMenu.tpl.html',
        controller: 'SignatureMenuController'
    }
}

function ContextMenuModel()
{
	this.selectedObject = {};
	this.parentObject = {};
	this.parentFolder = {};
	this.xPos = "";
	this.yPos = "";
	this.event = {};
	this.contextMenuCheckOptions = {};
	this.multiple = false;
	this.currentInstance = "";
}






angular
	.module('signatures', ['signatures.service', 'localization.service', 'image.service'])
	.config(configSignaturesController)
	.controller('SignaturesController', ['$scope', '$state', '$stateParams', 'signaturesService', 'localizedMessages', 'repositoryService', 'imageService', 'context', 'contextMenuModel', 'bvDialog', 'SortUtility', SignaturesController])
	.controller('SignatureStatusController', ['$scope', 'context', 'localizedMessages', 'legacyUserService', 'SortUtility', SignatureStatusController])
	.controller('SignatureMenuController', SignatureMenuController)
	.directive('signatureMenu', signatureMenu)
	.service('signatureContextMenu', SignatureContextMenu)
    .service('contextMenuModel', ContextMenuModel)