function createAgendaTemplateConfig($stateProvider, $locationProvider){
	
}
createAgendaTemplateController.$inject = ['$scope', '$state', 'homeService','reactInterface', 'bvDialog', '$stateParams', 'localizedMessages', 'repositoryService'];

function createAgendaTemplateController($scope, $state, homeService, reactInterface, bvDialog, $stateParams, localizedMessages, repositoryService){
	
    $scope.reactInterface = reactInterface;
	$scope.operation = $stateParams.operation;
	$scope.templateName = $stateParams.templateName;
	$scope.templateID = $stateParams.templateID;
    $scope.middleBarObject.showBack = true;
    var templateName = $scope.operation === "edit" ? $scope.templateName : localizedMessages.get("CREATE_AGENDA_TEMPLATE");
  
    $scope.middleBarObject.title = localizedMessages.get("MEETINGS") + ' | ' + templateName;
	$scope.tabs = [{
        title : localizedMessages.get("MANAGE_TEMPLATES"),
        content : 'manageTemplates',
        active: true,
        rightMenuList:[]
    }];
    $scope.handleLeftIcon = function(){
        if(homeService.get_current_module_has_unsaved_changes()){
            event.preventDefault();
            bvDialog.confirm(localizedMessages.get('MSG_EXIT_VEDIT'), function(){
                reactInterface.set_meeting_module_has_unsaved_changes(false, 'createAgendaTemplateController');
                $state.go('home.manageTemplates');
              }); 
        }
        else {
            $state.go('home.manageTemplates');
        }
       		
	}
}

