viewfullagendaService.$inject = ['$q', 'ajaxService'];

function viewfullagendaService($q, ajaxService) {
    var agendaMeetingId = '';
	var service = {
			getFormattedTime: function(currentUser, startTime, timeZone) {
				var dateHelper = new DateHelper(currentUser);

				return dateHelper.getFormattedTime(startTime, timeZone);
			},
			setAgendaMeetingId: function(val){        	
        		agendaMeetingId = val;        	
			},
			getAgendaMeetingId: function(){
	        	return agendaMeetingId;
			},
			getAllDigitalAgendasForTheCalendarEvent: function(fileObject){
				var request = ajaxService.getAllDigitalAgendasForTheCalendarEvent(fileObject);
				return request.then(function(response) {
					if (response) {					
						return response.data;
					}
					return null;
				});
			},
			getDigitalAgendaConfigurations: function(calendarBaseuuid){
				var request = ajaxService.getDigitalAgendaConfigurations(calendarBaseuuid);
				return request.then(function(response) {
					if (response) {					
						return response.data;
					}
					return null;
				});
			},
			loadGuestandResponseForEventWithoutAttachmentsJSON: function(objectBaseUuid) {
				var request = ajaxService.loadGuestandResponseForEventWithoutAttachmentsJSON(objectBaseUuid);
				return request.then(function(data){
					return data;
				});
				return "";	
			},
			updateDocReadStatus: function(collateUuid,docUuid) {
				var request = ajaxService.updateAgendaDocviewedUsers(collateUuid,docUuid);
				return request.then(function(data){
					return data;
				});
				return "";	
			},
			
	}
	return service;
}
	