function appletService(){
	this.getAppletGetCert = {}
	this.setCertificateValue = {}
	this.getAppletToken = {}
	this.setTokenValue = {}
	this.installationDone = {}
	this.getAppletInstallCert = {}
}

function appletDirective(){
	return{
		restrict: 'A',
		controller: ['$scope', '$cookies', '$compile', 'appletService', '$element', function($scope, $cookies, $compile, appletService, $element){
			
			appletService.getAppletGetCert = function(baseuuid, certIds, dateformat)
			{
				var appletHtml = "<applet name='" + baseuuid + "get-certs' id='" + baseuuid + "get-certs' codebase='/dvclient/' ";                
		        appletHtml += "code='com.boardvantage.dv.applet.core.SingleContentProcessor.class' width='1' height='1'>";
		        appletHtml += "<param name='cache_archive' value='dvvault.jar'/>";
		        appletHtml += "<param name='useruuid' value='" + baseuuid + "'/>";
		        appletHtml += "<param name='baseuuid' value='base'/>";
			    appletHtml += "<param name='jsessionid' value='" + $cookies.JSESSIONID + "'/>";
		        appletHtml += "<param name='processName' value='get-certs'/>";
		        appletHtml += "<param name='certificateids' value='" + certIds + "'/>";
		        appletHtml += "<param name='dateformat' value='" + dateformat + "'/>";
		        appletHtml += "</applet>";
		        var el = document.createElement('div')
		        el.innerHTML = appletHtml;
		        $element.append(el);
		        $compile($(el).contents())($scope);
		   };
			
		   appletService.getAppletToken = function(baseuuid, token, serial)
		    {
		    	var appletHtml = "<applet  name='" + baseuuid + "decrypt-token' id='" + baseuuid + "decrypt-token' codebase='/dvclient/' ";                
		        appletHtml += "code='com.boardvantage.dv.applet.core.SingleContentProcessor.class' width='1' height='1'>";
		        appletHtml += "<param name='cache_archive' value='dvvault.jar'/>";
		        appletHtml += "<param name='useruuid' value='"+baseuuid+"'/>";
		        appletHtml += "<param name='certtoken' value='"+token+"'/>";
		        appletHtml += "<param name='certserial' value='"+serial+"'/>";
		        appletHtml += "<param name='baseuuid' value='base'/>";
			    appletHtml += "<param name='jsessionid' value='" + $cookies.JSESSIONID + "'/>";
		        appletHtml += "<param name='processName' value='decrypt-token'/>";
		        appletHtml += "</applet>";
		        var el = document.createElement('div')
		        el.innerHTML = appletHtml;
		        $element.append(el);
		        $compile($(el).contents())($scope);
		    };
		    
		   appletService.getAppletInstallCert = function(bvsessid)
		    {
		    	var appletHtml = "<applet  name='zrrdsaUuU554xgsgenerate-csr' id='zrrdsaUuU554xgsgenerate-csr' codebase='/dvclient/' ";                
		        appletHtml += "code='com.boardvantage.dv.applet.core.SingleContentProcessor.class' width='1' height='1'>";
		        appletHtml += "<param name='cache_archive' value='dvvault.jar'/>";
		        appletHtml += "<param name='processName' value='generate-csr'/>";
				appletHtml += "<param name='jsessionid' value='" + $cookies.JSESSIONID + "'/>";
				appletHtml += "<param name='ecxsessionid' value='" + bvsessid + "'/>";
				appletHtml += "<param name='baseuuid' value='zrrdsaUuU554xgs'/>";
		        appletHtml += "</applet>";
		        var el = document.createElement('div')
		        el.innerHTML = appletHtml;
		        $element.append(el);
		        $compile($(el).contents())($scope);
		    }
		   
			window.setCertificateValue = function(param){
				appletService.setCertificateValue(param)
			},
			window.setTokenValue = function(tokenValue){
				appletService.setTokenValue(tokenValue);
			},
			window.installationDone = function(result){
				appletService.installationDone(result)
			}
		}]
	}
}

angular
	.module('appletDirective.directive', [])
	.service('appletService', appletService)
	.directive('appletDirective', [appletDirective])