eventsdialogController.$inject = ['$scope', '$element', '$timeout', '$state', 'calendarService', '$rootScope', 'localizedMessages', 'bvDialogMobile', 'reactInterface', 'bvDialog', 'context'];

function eventsdialogController($scope, $element, $timeout, $state, calendarService, $rootScope, localizedMessages, bvDialogMobile, reactInterface, bvDialog, context){
    $scope.events = $scope.bvModalControllerInputs.events;
    var bvModal = $scope.bvModalControllerInputs.bvModal;
    var day = $scope.bvModalControllerInputs.day;
    var browserEvent = $scope.bvModalControllerInputs.browserEvent;
    var clickedDay = $scope.bvModalControllerInputs.clickedDay;
    $scope.clickedDay = $scope.bvModalControllerInputs.clickedDay;
	$scope.localTimeZone = function(eventStartDate){
		var numbersOnlyRegex = /^[+-]?\d+$/;
        var tz = moment(eventStartDate).tz(moment.tz.guess()).format('z');
		if(numbersOnlyRegex.test(tz)){
			switch(moment.tz.guess()) {
			  case "Asia/Singapore":
			    return 'SGT';
			    break;
			  case "Pacific/Fiji":
				return moment(eventStartDate).tz(moment.tz.guess()).format('UTCZ')+' Fiji';
			    break;
			  default:
				return moment(eventStartDate).tz(moment.tz.guess()).format('UTCZ');
			}
		}else{
			return tz
		}
	};
    $scope.title = {
        day: moment(day.day).format('LL')
    }

    // MEETX-40568
    $scope.displayFormattedTime = function(startDate, endDate) {
        var dateHelper = new DateHelper(context.getModel().userVO);

		return dateHelper.getFormattedRangeTime(startDate, endDate, moment.tz.guess(true));
    }

    $scope.click = function(event){
        var uniq_date = StringUtils.momentL(event.start).format('ll');
        var events = calendarService.getAllCalendarEvents().eventsMap.get(uniq_date);

        if(MobileUtil.isPhone() || MobileUtil.isIPhone()){
            bvDialogMobile.mobileMeetingDetails(events, event.objectBaseUuid);
        }
        else{
          bvDialog.showMeetingDetailsReact(event.objectBaseUuid, event.startDate, event.endDate, clickedDay)
            //calendarService.tabs.forEach(tab => tab.active = false);


            // reactInterface.clickedEventFromMeetX = {
            //   date: uniq_date,
            //   eventBaseUuid: event.objectBaseUuid,
            //   clickedDay: uniq_date
            // }
			      // calendarService.tabs[1].active = true;

            // var EVENTS_LIST = {
            //     DATE: uniq_date,
            //     EVENTS: events,
            //     SELECTED_EVENT: event.objectBaseUuid
            // };
            // $rootScope.EVENTS_LIST = EVENTS_LIST;
            // $rootScope.SELECTED_TAB_FROM_CONF = ""+localizedMessages.get('DAILY');
            // $state.go('home.meetingDetails');
        }
        bvModal.close();
    }

    var rect = browserEvent.currentTarget.getBoundingClientRect();
    var deltaLeft = 200;
    var deltaTop = 70;
    var eventHeight = 74;
    var header = 50;
    var footer = 35;
    var length = $scope.events.length > 5 ? 5 : $scope.events.length;
    var dialogHeight = (eventHeight * length) + header;
    var dialogEl = $element.parents('.modal-dialog');

    $scope.leftDialog = true;

    if((rect.left - deltaLeft) < 185){
        $scope.leftDialog = false;
        dialogEl[0].style.left = (rect.left + rect.width + deltaLeft) + 'px';
    }
    else{
        $scope.leftDialog = true;
        dialogEl[0].style.left = (rect.left - deltaLeft) + 'px';
    }

    dialogEl[0].style.top = rect.top + rect.height/2 + 'px';

    var bottom = rect.top + rect.height/2 + dialogHeight/2;
    if( bottom > window.innerHeight){
        dialogEl[0].style.top = 'calc(100% - ' + (dialogHeight/2 + 2) + 'px)';
        $scope.arrowTop = (dialogHeight - 10) - window.innerHeight + rect.top + rect.height/2;
    }
    else
        $scope.arrowTop = (dialogHeight - 20)/2;

}