function HomeModel()
{
	this.plink = "";
	this.plinkObjUuid = "";
	this.resourceId = "";
	this.passwordPreferences = "";
	this.rootFolderForDocuments = "";
	this.editingDocs = [];
	this.listofPortals = [];
	this.briefcaseMobileMenu = {};
	this.vEditModal = {};
	this.selectedBriefCaseOption = "";
	this.selectedNewItemsOption = "";
	this.isSearchEnabled = { val : false };
	this.isBOParentofMeetingMinute = false;
}