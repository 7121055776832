function viewfullagendaConfig($stateProvider, $locationProvider){
	
}

viewfullagendaController.$inject = ['$scope', '$state', 'reactInterface',  'viewfullagendaService', '$stateParams', 'repositoryService', 'meetingsService', 'localizedMessages', '$timeout'];

function viewfullagendaController($scope, $state, reactInterface, viewfullagendaService, $stateParams, repositoryService, meetingsService, localizedMessages, $timeout){
	console.log('View full agenda page');
	 $scope.reactInterface = reactInterface;
	 $scope.fileObject = $stateParams.fileObject;
	 $scope.currentViewFullAgendaPath = $stateParams.currentViewFullAgendaPath;
	 $scope.annotationFromPlink = $stateParams.annotationFromPlink;
	 $scope.docId = '';
	 viewfullagendaService.setAgendaMeetingId($stateParams.fileObject);
	 $scope.localTimeZone = moment($scope.meetingStartTIme).tz(moment.tz.guess(true)).format('z'); //moment.tz.zone(moment.tz.guess()).abbr(new Date().getTimezoneOffset());
	$scope.tab = [{"name":"AGENDA", "active":true, "value": localizedMessages.get("AGENDA")},{"name":"PDF","value": localizedMessages.get("PDF_UPPERCASE"),"active":false,}]	
	$scope.activeMenu = $scope.tab[0].name;
    $scope.noAttachmentMessage = localizedMessages.get("NO_ATTACHMENT_AVAILABLE_MESSAGE");
    $scope.showViewer = false;
    $scope.middleBarObject.showBack = true;
    $scope.handleLeftIcon = function(){
    	$scope.middleBarObject.showBack = false;
    	if($scope.currentViewFullAgendaPath=='home.meetings'){
    		$state.go('home.meetings');
    	}
    	else if($scope.currentViewFullAgendaPath=='home.dashboard'){
    		$state.go('home.dashboard');
    	}
	}
	$scope.setActive = function(menuItem) {
		$scope.activeMenu = menuItem;
		
		if(menuItem == 'PDF'){
			$timeout( function(){
				$scope.openAgendaDPDFViewer($scope.agendaPdfCollateUuid);
	        }, 500 );
		}else{
			$timeout( function(){
				$scope.openAgendaDocumentViewer($scope.agendaPreviewCollateUuid ? $scope.agendaPreviewCollateUuid : 'ERR_NO_ATTACHMENTS', false);
	        }, 500 );
		}
	}
	$scope.showPane = false;
	$scope.paneController = function() {
	    $scope.showPane = !$scope.showPane;
	}; 
	$scope.closePaneController = function() {
		 $scope.showPane = !$scope.showPane;
	}; 
	
	
	$scope.romanize = function(num){
	    var lookup = {M:1000,CM:900,D:500,CD:400,C:100,XC:90,L:50,XL:40,X:10,IX:9,V:5,IV:4,I:1},roman = '',i;
	    for ( i in lookup ) {
	      while ( num >= lookup[i] ) {
	        roman += i;
	        num -= lookup[i];
	      }
	    }
	    return roman;
	  };

	$scope.alpha = function(n) {
	    var x = n-1,
            r26 = x.toString(26),
            baseCharCode = "A".charCodeAt(0);

        var arr = r26.split(''),
            len = arr.length;

        var newArr =arr.map(function(val,i){
            val = parseInt(val,26);

            if( (i === 0) && ( len > 1)){
                val = val-1;
            }

            return String.fromCharCode(baseCharCode + val);
        });
        return newArr.join('');
	  };

	$scope.getOrderStyle = function(str, orderType){
		if(str===''){
			return '';
		}
	    var arry = str.split('.');
	    var convertedStr = null;
	    if(orderType === 'roman'){
	        arry.forEach(element => {
	            if(convertedStr){
	                convertedStr = convertedStr + '.' + $scope.romanize(element);
	            }else{
	                convertedStr =  $scope.romanize(element);
	            }
	        });
	        return convertedStr
	    }else if(orderType === 'characters'){
	        arry.forEach(element => {
	            if(convertedStr){
	                convertedStr = convertedStr + '.' + $scope.alpha(element).toUpperCase();
	            }else{
	                convertedStr =  $scope.alpha(element).toUpperCase();
	            }
	        });
	        return convertedStr
	    }else{
	        return str
	    }
	    
	  };
	  
	
	  
	$scope.agendaArray = new Array();
	
	  $scope.convertToList = function (agendaList, level = 1, parentIndex=null, displayIndex=null){
		  var displayCounter = 0;
		  agendaList.forEach( (agenda,i) =>{
			  agenda.level = level;
			  agenda.index = parentIndex ? parentIndex+"."+(i+1) : ""+(i+1);
		  if(agenda.isBreak){
			  agenda.displayIndex = '';
		  } else {
			  agenda.displayIndex = displayIndex ? displayIndex+"."+(displayCounter+1) : ""+(displayCounter+1);
			  displayCounter = displayCounter+1;
		  }
		  $scope.agendaArray.push(agenda);
		  if(agenda.childLineItems && agenda.childLineItems.length >0 ){
			  $scope.convertToList(agenda.childLineItems, level+1, agenda.index, agenda.displayIndex);
		  }
		})
	  };
	  
	  
	  $scope.calculateDuration = function (list, parentStartTime){
		  var totalDuration = 0;
			console.log("viewfullagenda calculateDuration");
		  list.forEach(function(item, i) {
		      if(i==0){
		          item.startTime=parentStartTime;
		          item.displayStartTime =  StringUtils.momentL(item.startTime).format('hh:mm A');
		      } else {
		          const previousDuration = list[i-1].durationInMinutes;
		          const previousStartTime = list[i-1].startTime;
		          item.startTime = $scope.addMinutes(previousStartTime, previousDuration?previousDuration:0);
		          item.displayStartTime = StringUtils.momentL(item.startTime).format('hh:mm A');
		      }
		      if(item.childLineItems && item.childLineItems.length>0){
		    	  item.durationInMinutes = $scope.calculateDuration(item.childLineItems, item.startTime);
		      }
		      totalDuration = totalDuration + (+item.durationInMinutes);
		  });
		  return totalDuration;
		};
		
		$scope.addMinutes = function(time, minutes)  {
			 return StringUtils.momentL(time).add(minutes, 'minutes');
			};
		
	$scope.getAllDigitalAgendasForTheCalendarEvent = function(fileObject){
		viewfullagendaService.getAllDigitalAgendasForTheCalendarEvent(fileObject).then(function(response) {
				if(response){
					$scope.digitalAgendasForCalendarData = response.digitalAgendas;	
					$scope.agendaPdfCollateUuid = response.agendaPdfCollateUuid;
					$scope.agendaPreviewCollateUuid = response.agendaPreviewCollateUuid;
					$scope.convertToList($scope.digitalAgendasForCalendarData);
					console.log($scope.meetingStartTIme + ' meeting time with list');
					$scope.calculateDuration($scope.digitalAgendasForCalendarData, $scope.meetingStartTIme);
					$scope.agendaArray.forEach(function (item) {
						 item.orderStyle = $scope.getOrderStyle(item.displayIndex, $scope.digitalAgendaConfigurations.orderStyle);						
				        });
					if(!$scope.digitalAgendasForCalendarData[0].agendaCollateUuid){
						$scope.checkForAgendaCollateUuid = true;
					}
					$scope.showViewer = true;
					if($scope.activeMenu === 'AGENDA'){
				    	$timeout( function(){	
							$scope.openAgendaDocumentViewer(response.agendaPreviewCollateUuid ? response.agendaPreviewCollateUuid : 'ERR_NO_ATTACHMENTS', response.digitalAgendas[0].objectBaseUuid, false);
				        }, 500 );
				    }
				}
			});
	};
	
	$scope.getDigitalAgendaConfigurations = function(calendarEventBaseUuid){
		viewfullagendaService.getDigitalAgendaConfigurations(calendarEventBaseUuid).then(function(response) {
				if(response){
					$scope.digitalAgendaConfigurations = response;	}
			});
	};
	
	$scope.loadGuestandResponseForEventWithoutAttachmentsJSON = function(fileObject){
		meetingsService.loadGuestandResponseForEventWithoutAttachmentsJSON(fileObject).then(function(response) {
				if(response){
					$scope.meetingItemResponse = response.data;	
					$scope.meetingStartTIme = $scope.meetingItemResponse.startDateGMTMillis;
					$scope.meetingTitile = $scope.meetingItemResponse.name;
					$scope.middleBarObject.title = $scope.meetingTitile;
					$scope.tabs = [
						{title : $scope.middleBarObject.title, content : $scope.middleBarObject.title, active: true, rightMenuList:''}
					];
				
					console.log($scope.meetingStartTIme + ' meeting time');
					
				}
			});
	};
	
	$scope.openAgendaDocumentViewer = function (documentVO, objectId, agendaPreview) {
		$scope.$parent.currentState.value = 'home.meetings';
		if(documentVO){
			$scope.docId = btoa(documentVO);
			repositoryService.openAgendaPdfViewer(documentVO, agendaPreview,null,null,$scope.annotationFromPlink);			
		}else{
			$scope.checkForAgendaCollateUuid = true;
			$('.PdfViewerWrap').remove();
		}
		
		 $scope.selected = objectId;
	};
	
	$scope.openAgendaDPDFViewer = function (documentVO) {
		if(documentVO){
			repositoryService.openAgendaPdf(documentVO);			
		}else{
			$scope.checkForAgendaCollateUuid = true;
			//$('.PdfViewerWrap').remove();
		}
		
		 //$scope.selected = objectId;
	};
	
	$scope.isSelected = function(documentVO) {
	        return $scope.selected === documentVO;
	    };
	$scope.init = function(){	
		$scope.loadGuestandResponseForEventWithoutAttachmentsJSON($scope.fileObject);
		$scope.getDigitalAgendaConfigurations($scope.fileObject);
		$timeout( function(){			
			$scope.getAllDigitalAgendasForTheCalendarEvent($scope.fileObject);
        }, 500 );
		$('.meeting-details-react').remove();
	}
	$scope.init();
}

exportDigitalAgendaController.$inject = [ '$scope', 'reactInterface' ];

function exportDigitalAgendaController($scope, reactInterface) {
	const bvModal = $scope.bvModalControllerInputs.bvModal;
	$scope.reactInterface = reactInterface
	$scope.agendaPdfUuid = $scope.bvModalControllerInputs.agendaPdfUuid;
	$scope.closeDialog = function() {
		bvModal.close();
	}
}

