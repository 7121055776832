function messagesService($q, ajaxService, responseValidatorService, localizedMessages) {

	var expiryOptions = {
		"1_WEEK"   : "1 "+localizedMessages.get('WEEK'),
		"1_MONTH"  : "1 "+localizedMessages.get('MONTH'),
		"3_MONTHS" : "3 "+localizedMessages.get('MONTHS'),
		"6_MONTHS" : "6 "+localizedMessages.get('MONTHS')
	};
	
	var expiryOptionsNameValue =[
		{name:"1 "+localizedMessages.get('WEEK'), value:"1_WEEK"}, 
		{name:"1 "+localizedMessages.get('MONTH'), value:"1_MONTH"}, 
		{name:"3 "+localizedMessages.get('MONTHS'), value:"3_MONTHS"}, 
		{name:"6 "+localizedMessages.get('MONTHS'), value:"6_MONTHS"}
	];

	var service = {

		composeMessageJSON: function(toSecurityUuidList, subject, comments, attachmentUuidList, permissionType, secureMessage, newitemEnabled, briefcaseEnabled, filesUuidList, selectedExpiry) {
			var request = ajaxService.composeMessageJSON(toSecurityUuidList, btoa(encodeURI(subject)), btoa(encodeURI(comments)), attachmentUuidList, permissionType, secureMessage, newitemEnabled, briefcaseEnabled, filesUuidList, selectedExpiry);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},
		replyShareContainerJSON: function(shareParentBaseUuid, toSecurityUuidList, subject, comments, attachmentUuidList, permissionType, secureMessage, newitemEnabled, briefcaseEnabled, filesUuidList) {
			var request = ajaxService.replyShareContainerJSON(shareParentBaseUuid, toSecurityUuidList, btoa(encodeURI(subject)), btoa(encodeURI(comments)), attachmentUuidList, permissionType, secureMessage, newitemEnabled, briefcaseEnabled, filesUuidList);
			return request.then(function(response) {
				if (response) {
					return response.data;
				}
				return null;
			});
		},

		getInboxJSON: function(offset) {
			var request=ajaxService.getInboxJSON(offset);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},

		getSentJSON: function(offset) {
			var request=ajaxService.getSentJSON(offset);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},

		getSearchLimit: function() {
			var request=ajaxService.getSearchLimit();
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},
		
		markItemRead: function(objectBaseUuid) {
			var request=ajaxService.markItemRead(objectBaseUuid);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},

		getSharedContainerWithAttachmentsJSON: function(shareContainerObjectBaseUuid) {
			var request=ajaxService.getSharedContainerWithAttachmentsJSON(shareContainerObjectBaseUuid);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			})
		},

		getExpiryOptions: function()
		{
		   return expiryOptionsNameValue;
		},

		getExpiryString: function(key) {
			return expiryOptions[key];
		},
		
		isSecureEnabled: function() {
			var request=ajaxService.isSecureEnabled();
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},
		deletedUserSharedObjectJSON: function(objectBaseUuidList) {
			var request=ajaxService.deletedUserSharedObjectJSON(objectBaseUuidList);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},
		deleteSentItemObjectJSON: function(objectBaseUuid) {
			var request=ajaxService.deleteSentItemObjectJSON(objectBaseUuid);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},
		deleteInboxItemObjectJSON: function(objectBaseUuid) {
			var request=ajaxService.deleteInboxItemObjectJSON(objectBaseUuid);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},
		recallSentItemObjectJSON: function(objectBaseUuid) {
			var request=ajaxService.recallSentItemObjectJSON(objectBaseUuid);
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		},
		markAllInboxItemsAsRead: function() {
			var request=ajaxService.markAllInboxItemsAsRead();
			return request
			.then(function(data) {
				return data;
			}, function(data) {
				return null;
			});
		}
		
	}
	return service;
}
angular
	.module('messages.service', [ 'ajax.service' ])
	.factory('messagesService',[ '$q', 'ajaxService', 'responseValidatorService','localizedMessages', messagesService ]);