plinkHandlerService.$inject = ['homeService','homeModel', 'repositoryService', '$state','localizedMessages','bvDialog', '$timeout','context','messagesService','approvalsService'];

function plinkHandlerService(homeService, homeModel, repositoryService, $state, localizedMessages, bvDialog, $timeout,context,messagesService,approvalsService){
	this.checkForPermalinkHandler = function(defaultLandingModule)
	{
		if(!StringUtils.isNullEmpty(homeModel.plink))
		{
			if(homeModel.plink.indexOf("task") == 0 || homeModel.plink.indexOf("project") == 0){
				homeService.setPlinkValForCS(homeModel.plink);
				$state.go("home.taskmanagement");
			}
			else if(homeModel.plink.indexOf("email") == 0){
				homeService.setPlinkValForCS(homeModel.plink);
				$state.go("home.email");
			}
			else if (homeModel.plink.indexOf("document") == 0) {
			    var documentId = atob(homeModel.plink.split("document")[1])
                $timeout(function () {
                    repositoryService.openPdfViewer(documentId)
                })
			}
			else{
				homeService.getPermalinkOrResourceVersionId(homeModel.plink, "").then(function(data)
				{
					getPermalinkCallback(data, defaultLandingModule);
		 		});
			}

			homeModel.plink = "";
			homeModel.plinkObjUuid = "";
		}
	}

	this.checkForResourcelinkHandler = function(defaultLandingModule)
	{
		homeService.getPermalinkOrResourceVersionId("", homeModel.resourceId).then(function(data)
		{
			getPermalinkCallback(data, defaultLandingModule);
 		});
		homeModel.resourceId = "";
	}
	
	var load_Folder_Link=function(obj) 
	{
 		$state.go('home.documents',{'folderBaseUuid':obj,'open':'true'});
 	}
	

	var getPermalinkCallback = function(businessObject, defaultLandingModule)
	{
		
		if(!businessObject.objectBaseUuid || businessObject.boName=="FailureBO")
		{ 
			bvDialog.showMessage(localizedMessages.get('ACCESS_DENIED'));
			var homeController = angular.element(document.querySelector('[home-controler]')).scope();
        	homeController.goToLandingModule(businessObject.objectBaseUuid);
		}
		else
		{
			if(StringUtils.isRepositoryObject(businessObject.objectBaseUuid))
			{
				var homeController = angular.element(document.querySelector('[home-controler]')).scope();
	        	repositoryService.openPdfViewer(businessObject.objectBaseUuid);
	        	homeController.goToLandingModule(businessObject.objectBaseUuid);
			}
			else if(StringUtils.isCalendarEvent(businessObject.objectBaseUuid))
			{
				var isBOParentofMeetingMinute = businessObject.isBOParentofMeetingMinute;
				if (isBOParentofMeetingMinute) {
					homeModel.isBOParentofMeetingMinute = isBOParentofMeetingMinute;
				}
				
				var homeController = angular.element(document.querySelector('[home-controler]')).scope();
	        	homeController.handleEventPlink(businessObject.objectBaseUuid);
	        }
			else if(StringUtils.isSharedContainer(businessObject.objectBaseUuid) || StringUtils.isApprovalShareContainer(businessObject.objectBaseUuid) || StringUtils.isSurveyShareContainer(businessObject.objectBaseUuid))
			{
				homeModel.plinkObjUuid = businessObject.objectBaseUuid;
				if(StringUtils.isApprovalShareContainer(businessObject.objectBaseUuid))
				{
					messagesService.getSharedContainerWithAttachmentsJSON(homeModel.plinkObjUuid)
					.then(function(response) {
						if(response.data && response.data.attachments && response.data.attachments.length>0)
							{
							   approvalsService.setApprovalOpenFromInbox(response.data.attachments[0]);
							   $state.go('home.approval');					
							}	
						else
							{
								$state.go('home.approvals');
							}
					});
				}
				else
					{
					if(homeService.isSecureEmailModuleEnabled())
					{
						$state.go('home.email');
					}
					else
					{
						$state.go('home.inbox');	
					}
					}
			}
			else if(StringUtils.isSharedAnnotationContainer(businessObject.objectBaseUuid))
			{
				var homeController = angular.element(document.querySelector('[home-controler]')).scope();
	        	var docbaseUuid = businessObject.sharedBusinessObject.objectBaseUuid;
	        	if(businessObject.annotationObjectUuid!=undefined){
		        var annotationObjectUuid=businessObject.annotationObjectUuid;
		        repositoryService.openPdfViewer(docbaseUuid,null,null,annotationObjectUuid);
		        }
				else
				{
	        	repositoryService.openPdfViewer(docbaseUuid);
				}
	        	homeController.goToLandingModule(docbaseUuid);
			}
			else if(StringUtils.isSharedAgendaAnnotationContainer(businessObject.objectBaseUuid))
			{
				
				 var calObj='';
				 var annotUuid='';
				 if(!StringUtils.isNullEmpty(businessObject.agendaCalEventUuid))
				 {
					 calObj=businessObject.agendaCalEventUuid;
				 }
				 
				 if(!StringUtils.isNullEmpty(businessObject.sharedBusinessObject) && !StringUtils.isNullEmpty(businessObject.sharedBusinessObject.objectBaseUuid))
				 {
					 annotUuid=businessObject.sharedBusinessObject.objectBaseUuid;
				 }
				 
				 if(!StringUtils.isNullEmpty(calObj) && !StringUtils.isNullEmpty(annotUuid))
				 {
					 $state.go('home.viewfullagenda', {
						 fileObject: calObj,
						 currentViewFullAgendaPath: 'home.meetings',
						 annotationFromPlink:annotUuid
					 });    	
				 }
			}

			else if(StringUtils.isFolder(businessObject.objectBaseUuid))
			{
				var oldTs = context.getModel().portalVO.objectBaseUuid;
				var selectedFolderTs=businessObject.portalBaseUuid;
				if(oldTs!=selectedFolderTs)
				{
					var teamSpaceobject={"objectBaseUuid":selectedFolderTs};
					homeService.switchTeamSpace(teamSpaceobject,null);
					$timeout(function () {
						load_Folder_Link(businessObject.objectBaseUuid);
					  }, 15000);
				}
				else
				{
					$state.go('home.documents',{'folderBaseUuid':businessObject.objectBaseUuid,'open':'true'});
				}
	        }
		}
	}

}
